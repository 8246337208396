export const UploadOutlined = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.33366 12.6666H8.66699V9.88331L9.73366 10.95L10.667 9.99998L8.00033 7.33331L5.33366 9.99998L6.28366 10.9333L7.33366 9.88331V12.6666ZM4.00033 14.6666C3.63366 14.6666 3.31977 14.5361 3.05866 14.275C2.79755 14.0139 2.66699 13.7 2.66699 13.3333V2.66665C2.66699 2.29998 2.79755 1.98609 3.05866 1.72498C3.31977 1.46387 3.63366 1.33331 4.00033 1.33331H9.33366L13.3337 5.33331V13.3333C13.3337 13.7 13.2031 14.0139 12.942 14.275C12.6809 14.5361 12.367 14.6666 12.0003 14.6666H4.00033ZM8.66699 5.99998V2.66665H4.00033V13.3333H12.0003V5.99998H8.66699Z"
        fill="#1B2227"
      />
    </svg>
  );
};
