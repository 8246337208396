import { Box, IconButton, Typography } from "@mui/material";
import "./LoginTitle.scss";

import { ArrowBack } from "../../assets/Icons/ArrowBack";

export interface LoginTitleProps {
  text: string;
  onBackClick?: () => void;
}

export const LoginTitle = (props: LoginTitleProps) => {
  return (
    <Box className="login-title-container">
      {props.onBackClick && (
        <Box className={"login-title-icon"} onClick={props.onBackClick}>
          <IconButton>
            <ArrowBack />
          </IconButton>
        </Box>
      )}
      <Typography>{props.text}</Typography>
    </Box>
  );
};
