export const KebabHorizontal = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.00033 9.33335C3.63366 9.33335 3.31977 9.2028 3.05866 8.94169C2.79755 8.68058 2.66699 8.36669 2.66699 8.00002C2.66699 7.63335 2.79755 7.31946 3.05866 7.05835C3.31977 6.79724 3.63366 6.66669 4.00033 6.66669C4.36699 6.66669 4.68088 6.79724 4.94199 7.05835C5.2031 7.31946 5.33366 7.63335 5.33366 8.00002C5.33366 8.36669 5.2031 8.68058 4.94199 8.94169C4.68088 9.2028 4.36699 9.33335 4.00033 9.33335ZM8.00033 9.33335C7.63366 9.33335 7.31977 9.2028 7.05866 8.94169C6.79755 8.68058 6.66699 8.36669 6.66699 8.00002C6.66699 7.63335 6.79755 7.31946 7.05866 7.05835C7.31977 6.79724 7.63366 6.66669 8.00033 6.66669C8.36699 6.66669 8.68088 6.79724 8.94199 7.05835C9.2031 7.31946 9.33366 7.63335 9.33366 8.00002C9.33366 8.36669 9.2031 8.68058 8.94199 8.94169C8.68088 9.2028 8.36699 9.33335 8.00033 9.33335ZM12.0003 9.33335C11.6337 9.33335 11.3198 9.2028 11.0587 8.94169C10.7975 8.68058 10.667 8.36669 10.667 8.00002C10.667 7.63335 10.7975 7.31946 11.0587 7.05835C11.3198 6.79724 11.6337 6.66669 12.0003 6.66669C12.367 6.66669 12.6809 6.79724 12.942 7.05835C13.2031 7.31946 13.3337 7.63335 13.3337 8.00002C13.3337 8.36669 13.2031 8.68058 12.942 8.94169C12.6809 9.2028 12.367 9.33335 12.0003 9.33335Z"
        fill="#1B2227"
      />
    </svg>
  );
};
