import { LOCAL_STORAGE_KEYS } from "../../utils/constants";
import {
  GoogleLoginResponse,
  TelegramLoginResponse,
  User,
  UserService,
} from "../openapi";
import * as api from "../openapi/services/AuthService";

export default class AuthService {
  static async getAccessToken(): Promise<string | null> {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  }

  static login(token: string): string {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
    return token;
  }

  static async loginByGoogle(
    authCode: string,
    isNative: boolean,
  ): Promise<GoogleLoginResponse> {
    return api.AuthService.postApiAuthGoogleLogin2({
      authCode,
      fromNativeApp: isNative,
    });
  }

  static async loginByEmail(email: string, password: string): Promise<string> {
    return api.AuthService.postApiAuthLogin({ email, password });
  }

  static async loginToTelegramMiniApp(initData: string): Promise<TelegramLoginResponse> {
    return api.AuthService.postApiAuthTelegramLogin({ data: initData });
  }

  static async signUp(email: string, password: string): Promise<string> {
    return api.AuthService.postApiAuthSignUp({ email, password });
  }

  static async getAndSaveUserProfile(): Promise<User> {
    try {
      const serverUserData: User = await UserService.getApiUsersProfile();
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, serverUserData.id || "");
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER_NAME,
        serverUserData.name || "",
      );
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER_LOGO_URL,
        serverUserData.profileUrl || "",
      );
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER_EMAIL,
        serverUserData.email || "",
      );
      return serverUserData;
    } catch (e) {
      if (e instanceof Error && "status" in e && (e.status === 401 || e.status === 500)) {
        await this.logoutCleanup();
        window.location.href = "/login-home";
      }
      throw e;
    }
  }

  static async logoutCleanup() {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ID);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_NAME);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_LOGO_URL);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_EMAIL);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  }
}
