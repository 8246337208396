export const Edit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.7375 14.25V14.2625H3.75H4.81875V14.2677L4.82759 14.2588L12.1588 6.92759L12.1677 6.91875L12.1588 6.90991L12.1587 6.90976L11.0902 5.84132L11.0901 5.84116L11.0813 5.83232L11.0724 5.84116L3.74116 13.1724L3.7375 13.1688V13.1813V14.25ZM14.2783 2.70845L14.2783 2.70845L14.2786 2.70876L15.3098 3.75876L15.3098 3.75877L15.3103 3.75921C15.4589 3.89539 15.5671 4.05622 15.6351 4.2418C15.7034 4.42802 15.7375 4.61406 15.7375 4.8C15.7375 4.99853 15.7034 5.18765 15.6352 5.36744C15.5671 5.54689 15.4588 5.71104 15.3099 5.85991L5.85991 15.3099C5.72358 15.4462 5.56559 15.5516 5.38585 15.626C5.20614 15.7003 5.01714 15.7375 4.81875 15.7375H3C2.79065 15.7375 2.61575 15.6668 2.47446 15.5255C2.33317 15.3842 2.2625 15.2094 2.2625 15V13.1813C2.2625 12.9829 2.29969 12.7939 2.37405 12.6142C2.44843 12.4344 2.55376 12.2764 2.69009 12.1401L12.1584 2.69046C12.1585 2.69041 12.1586 2.69035 12.1586 2.69029C12.3076 2.55382 12.4719 2.44844 12.6517 2.37405C12.8314 2.29969 13.0204 2.2625 13.2188 2.2625C13.4171 2.2625 13.6093 2.29969 13.7954 2.37411C13.9811 2.4484 14.142 2.55982 14.2783 2.70845Z"
        fill="#08875D"
        stroke="#08875D"
        strokeWidth="0.025"
      />
    </svg>
  );
};
