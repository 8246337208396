import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";

import config from "../../appConfig/config";
import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";
import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import {
  fetchShowRegistrationLastShow,
  setShowRegistationLastShow,
} from "../../store/application/applicationSlice";
import { selectAuthState } from "../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { deleteListItemById, updateListItem } from "../../store/lists/listsSlice";
import { EmptyContent } from "../EmptyContent/EmptyContent";
import { ListItemButton } from "../ListItemButton/ListItemButton";
import "./ListItems.scss";
import { WarningRegistrationAlert } from "../WarningRegistationAlert/WarningRegistationAlert";

export interface ListItemsProps {
  groupedByCategoryListItems: ListItemInternalModel[][];
  completedListItems: ListItemInternalModel[];
  prompts: ListItemPromptInternalModel[];
  categories: ListItemCategoryInternalModel[];
  isListItemCreationOpened: boolean;
  onListItemComplete: (id: number) => void;
  onListItemUnComplete: (id: number) => void;
  onDeleteAllListItems: () => void;
  onEditListItemClick: (id: number) => void;
  onChangeCategoryClick: (listItemId: number) => void;
  onSaveNewListItem: (listItemName: string, quantity: number) => void;
  onCancelListItemCreation: () => void;
}

export const ListItems = (props: ListItemsProps) => {
  const dispatch = useAppDispatch();
  const { signedIn } = useAppSelector(selectAuthState);
  const listItemButton = useRef<HTMLDivElement>(null);

  const handleListItemClick = (id: number) => {
    props.onListItemComplete(id);
  };

  const handleCompletedListItemClick = (id: number) => {
    props.onListItemUnComplete(id);
  };

  const handleDeleteAllListItems = () => {
    props.onDeleteAllListItems();
  };

  const handleDeleteListItem = (id: number) => {
    dispatch(deleteListItemById(id));
  };

  const handleListItemQuantityChange = (
    localId: number,
    quantity: number,
    unit: string,
  ) => {
    dispatch(updateListItem({ localId, quantity, unit }));
  };

  const onShouldRegistrationAlertShow = async (interval: number) => {
    const lastShow = Number((await dispatch(fetchShowRegistrationLastShow())).payload);
    return !signedIn && Date.now() - lastShow > interval;
  };

  const onAfterRegistrationAlertShow = async () => {
    await dispatch(setShowRegistationLastShow(Date.now()));
  };

  useEffect(() => {
    if (props.isListItemCreationOpened && listItemButton.current) {
      listItemButton.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.isListItemCreationOpened]);

  return (
    <Box className="list-items">
      <Typography className="list-items-title" component="h2">
        Список
      </Typography>
      <Box className="list-items-body">
        {!!props.groupedByCategoryListItems?.length && (
          <WarningRegistrationAlert
            delay={config.ShowRegistrationDelay}
            interval={config.ShowRegistrationInterval}
            onShouldShow={onShouldRegistrationAlertShow}
            onAfterShow={onAfterRegistrationAlertShow}
          />
        )}
        {!props.groupedByCategoryListItems?.length &&
        !props.completedListItems?.length &&
        !props.isListItemCreationOpened ? (
          <EmptyContent subtitle="Добавьте необходимые позиции голосовым или ручным вводом" />
        ) : (
          <List className="list-items-uncompleted">
            {props.groupedByCategoryListItems.map((groupOfListItems, index) => (
              <ListItem className="list-items-uncompleted-group" key={index}>
                {index !== 0 && <Divider className="list-items-groups-divider" />}
                <List>
                  {groupOfListItems.map((listItem) => (
                    <ListItem key={listItem.localId}>
                      <ListItemButton
                        name={listItem.name}
                        localId={listItem.localId}
                        category={listItem.localCategory}
                        quantity={listItem.quantity}
                        unit={listItem.unit}
                        onQuantityChange={handleListItemQuantityChange}
                        isChecked={false}
                        onClick={handleListItemClick}
                        onChangeCategoryClick={props.onChangeCategoryClick}
                        onEditClick={props.onEditListItemClick}
                        onDeleteClick={handleDeleteListItem}
                        isListItemCreationOpened={props.isListItemCreationOpened}
                      />
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            ))}
          </List>
        )}
        {props.isListItemCreationOpened && (
          <div ref={listItemButton}>
            <ListItemButton
              prompts={props.prompts}
              isChecked={false}
              onSaveNewListItem={props.onSaveNewListItem}
              onCancelListItemCreation={props.onCancelListItemCreation}
              onInputBlur={props.onCancelListItemCreation}
            />
          </div>
        )}
        <Divider className="list-items-divider" />
        {props.completedListItems?.length !== 0 && (
          <Accordion disableGutters defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="list-items-completed-heading">
                Завершенные ({props.completedListItems.length})
              </Typography>
              <IconButton
                className="list-items-completed-delete"
                onClick={handleDeleteAllListItems}
              >
                <DeleteOutlinedIcon
                  color="error"
                  className="list-items-completed-delete-icon"
                />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {props.completedListItems.map((listItem) => (
                  <ListItem key={listItem.localId}>
                    <ListItemButton
                      name={listItem.name}
                      localId={listItem.localId}
                      isChecked={true}
                      onClick={handleCompletedListItemClick}
                      onEditClick={props.onEditListItemClick}
                      onDeleteClick={handleDeleteListItem}
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </Box>
  );
};
