import { SequentialTaskRunner } from "../core/SequentialTaskRunner";
import { Syncer } from "../core/Syncer";

export class SyncService {
  private static intervalId: NodeJS.Timer | null = null;
  private static sequentialTaskRunner: SequentialTaskRunner = new SequentialTaskRunner();
  private static syncers: Syncer[] = [];
  private static checkSignIn: () => boolean = () => false;
  private static setIsOnline: (isOnline: boolean) => void = () => undefined;

  private static async getSyncersChangesScope(syncers: Syncer[]) {
    const changes = new Map<
      Syncer,
      { isBackendChanged: boolean; isFrontendChanged: boolean }
    >();
    for (const syncer of syncers) {
      let isBackendChanged: boolean;
      try {
        isBackendChanged = await syncer.changesTracker.isBackendChanged();
      } catch (e) {
        this.setIsOnline(false);
        throw e;
      }
      const isFrontendChanged = await syncer.changesTracker.isFrontendChanged();
      changes.set(syncer, { isBackendChanged, isFrontendChanged });
    }
    this.setIsOnline(true);
    return changes;
  }

  private static async runSyncers(force?: boolean, syncersToRun?: (typeof Syncer)[]) {
    const isSignedIn = this.checkSignIn();
    const syncers = syncersToRun?.length
      ? this.syncers.filter((s) => syncersToRun.includes(s.constructor as typeof Syncer))
      : this.syncers;
    if (isSignedIn) {
      const changesScope = await this.getSyncersChangesScope(syncers);
      for (const syncer of syncers) {
        const { isBackendChanged, isFrontendChanged } = changesScope.get(syncer) ?? {};
        if (isBackendChanged || isFrontendChanged || force) {
          await syncer.sync(true);
        }
      }
    } else {
      for (const syncer of syncers) {
        await syncer.sync(false);
      }
    }
  }

  public static enqueue(force?: boolean, syncersToRun?: (typeof Syncer)[]) {
    return new Promise<boolean>((resolve, reject) => {
      this.sequentialTaskRunner.enqueue(async () => {
        try {
          await this.runSyncers(force, syncersToRun);
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  public static start(
    interval: number,
    checkSignIn: () => boolean,
    setIsOnline: (isOnline: boolean) => void,
    syncers: Syncer[],
  ): void {
    if (!this.intervalId) {
      this.syncers = syncers;
      this.checkSignIn = checkSignIn;
      this.setIsOnline = setIsOnline;
      this.intervalId = setInterval(() => this.enqueue(), interval);
    }
  }

  public static stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
