import { Box } from "@mui/material";
import { ReactNode } from "react";

import "./TabPanel.scss";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  return (
    <Box className={`tab-panel ${props.value !== props.index ? `tab-panel_hidden` : ""}`}>
      {props.children}
    </Box>
  );
};
