import { ChangesTracker } from "./ChangesTracker";

export abstract class Syncer {
  public changesTracker: ChangesTracker;
  protected afterSyncCallback: () => Promise<void>;

  constructor(changesTracker: ChangesTracker, afterSyncCallback: () => Promise<void>) {
    this.changesTracker = changesTracker;
    this.afterSyncCallback = afterSyncCallback;
  }

  abstract sync(signedId: boolean): Promise<void>;
}
