export const SelectChevron = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99947 10.3757C7.91058 10.3757 7.82725 10.3617 7.74947 10.3337C7.67169 10.3061 7.59947 10.259 7.53281 10.1923L4.44947 7.109C4.32725 6.98678 4.26903 6.83389 4.27481 6.65033C4.28014 6.46722 4.34392 6.31456 4.46614 6.19233C4.58836 6.07011 4.74392 6.009 4.93281 6.009C5.12169 6.009 5.27725 6.07011 5.39947 6.19233L7.99947 8.79233L10.6161 6.17567C10.7384 6.05345 10.8912 5.995 11.0748 6.00033C11.2579 6.00611 11.4106 6.07011 11.5328 6.19233C11.655 6.31456 11.7161 6.47011 11.7161 6.659C11.7161 6.84789 11.655 7.00345 11.5328 7.12567L8.46614 10.1923C8.39947 10.259 8.32725 10.3061 8.24947 10.3337C8.17169 10.3617 8.08836 10.3757 7.99947 10.3757Z"
        fill="#8399A8"
      />
    </svg>
  );
};
