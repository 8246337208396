import config from "../appConfig/config";
import { LOCAL_STORAGE_KEYS } from "../utils/constants";

import { analytics } from "./analytics/AnalyticsService";
import AuthService from "./auth/AuthService";
import { OpenAPI } from "./openapi";

export const initServices = () => {
  const { ApiUrl, AmplitudeApiKey } = config;

  if (ApiUrl) {
    OpenAPI.BASE = ApiUrl;
    OpenAPI.TOKEN = async () => {
      return (await AuthService.getAccessToken()) || "";
    };
  }

  if (AmplitudeApiKey) {
    const userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
    analytics.init(AmplitudeApiKey, userId || undefined);
  }

  if (process.env.NODE_ENV !== "development" && !config.Debug) {
    console.log = () => null;
    console.time = () => null;
    console.timeEnd = () => null;
  }
};
