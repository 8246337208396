export const Backspace = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M11.9 16L14.5 13.4L17.1 16L18.5 14.6L15.9 12L18.5 9.4L17.1 8L14.5 10.6L11.9 8L10.5 9.4L13.1 12L10.5 14.6L11.9 16ZM9.5 20C9.18333 20 8.88333 19.9292 8.6 19.7875C8.31667 19.6458 8.08333 19.45 7.9 19.2L2.5 12L7.9 4.8C8.08333 4.55 8.31667 4.35417 8.6 4.2125C8.88333 4.07083 9.18333 4 9.5 4H20.5C21.05 4 21.5208 4.19583 21.9125 4.5875C22.3042 4.97917 22.5 5.45 22.5 6V18C22.5 18.55 22.3042 19.0208 21.9125 19.4125C21.5208 19.8042 21.05 20 20.5 20H9.5ZM5 12L9.5 18H20.5V6H9.5L5 12Z"
        fill="#E02D3C"
      />
    </svg>
  );
};
