import "./AiVoiceIntro.scss";

import { Box, Button, List, ListItem, Typography } from "@mui/material";

import { AiStars } from "../../assets/Icons/AiStars";
import { ChefHat } from "../../assets/Icons/ChefHat";
import { PersonSpeaking } from "../../assets/Icons/PersonSpeaking";
import { TabletList } from "../../assets/Icons/TabletList";
import { CurtainPopup } from "../CurtainPopup/CurtainPopup";

interface AiVoiceIntroProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const AiVoiceIntro = (props: AiVoiceIntroProps) => {
  return (
    <CurtainPopup open={props.open} onClose={props.onClose} className="ai-voice-intro">
      <Typography className="ai-voice-intro-title">
        <span>Умный ввод</span> разберёт всё&nbsp;по&nbsp;пунктам
      </Typography>
      <List className="ai-voice-intro-features">
        <ListItem className="ai-voice-intro-features-item">
          <TabletList />
          <Box className="ai-voice-intro-features-item-content">
            <Typography className="ai-voice-intro-features-item-content-title">
              Говорите списком
            </Typography>
            <Typography className="ai-voice-intro-features-item-content-text">
              «Морковь 2 кг, колбаса 2 шт»
            </Typography>
          </Box>
        </ListItem>
        <ListItem className="ai-voice-intro-features-item">
          <ChefHat />
          <Box className="ai-voice-intro-features-item-content">
            <Typography className="ai-voice-intro-features-item-content-title">
              Добавляет по рецепту
            </Typography>
            <Typography className="ai-voice-intro-features-item-content-text">
              «Продукты для оливье»
            </Typography>
          </Box>
        </ListItem>
        <ListItem className="ai-voice-intro-features-item">
          <PersonSpeaking />
          <Box className="ai-voice-intro-features-item-content">
            <Typography className="ai-voice-intro-features-item-content-title">
              Понимает естественную речь
            </Typography>
            <Typography className="ai-voice-intro-features-item-content-text">
              «Купи всё для пикника, не забудь мясо»
            </Typography>
          </Box>
        </ListItem>
      </List>
      <Box className="ai-voice-intro-button">
        <Button
          onClick={() => {
            props.onClose();
            props.onConfirm();
          }}
        >
          <AiStars /> Попробовать умный ввод
        </Button>
      </Box>
    </CurtainPopup>
  );
};
