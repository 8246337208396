import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import "./SettingsPage.scss";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import Language from "../../../assets/Icons/language.svg";
import Profile from "../../../assets/Icons/profile.svg";
import Theme from "../../../assets/Icons/theme.svg";
import { CurtainPopup } from "../../../components/CurtainPopup/CurtainPopup";
import { ResetDialog } from "../../../components/Dialogs/ResetDialog/ResetDialog";
import { FutureSettings } from "../../../components/FutuerSettings/FutureSettings";
import { IOSSwitch } from "../../../components/IOSswith/IOSswitch";
import { LoginTitle } from "../../../components/LoginTitle/LoginTitle";
import { ResetButton } from "../../../components/ResetButton/ResetButton";
import { SettingOption } from "../../../components/SettingOption/SettingOption";
import { SettingProfile } from "../../../components/SettingProfile/SettingProfile";
import {
  restoreData,
  setInstallDialogToggle,
  setAutoListCreationToggle,
} from "../../../store/application/applicationSlice";
import { selectApplicationState } from "../../../store/application/selectors";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

export const SettingsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { signedIn } = useAppSelector(selectAuthState);
  const { installDialogToggle, isOnline, autoListCreationToggle } =
    useAppSelector(selectApplicationState);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isThemeOpen, setIsThemeOpen] = useState(false);
  const [isDataRestoring, setIsDataRestoring] = useState(false);

  const handleReset = async () => {
    setIsDataRestoring(true);
    await dispatch(restoreData());
    setIsDataRestoring(false);
    navigate("/");
  };

  const handleProfileClose = () => {
    setIsProfileOpen(false);
  };

  const handleInstallDialogToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setInstallDialogToggle(event.target.checked));
  };

  const handleListCreationDateToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setAutoListCreationToggle(event.target.checked));
  };

  return (
    <Stack className="settings-container">
      <LoginTitle text="Настройки" onBackClick={() => navigate(-1)} />

      <Stack className="setting-options">
        <SettingOption
          icon={<img src={Profile} alt="profile" />}
          text="Мои данные"
          onClick={() => setIsProfileOpen(true)}
        />
        <Divider className="setting-option__divider" />
        <SettingOption
          icon={<img src={Language} alt="language" />}
          text="Язык интерфейса"
          onClick={() => setIsLanguageOpen(true)}
        />
        <Divider className="setting-option__divider" />
        <SettingOption
          icon={<img src={Theme} alt="language" />}
          text="Тема оформления"
          onClick={() => {
            setIsThemeOpen(true);
          }}
        />
        <Divider className="setting-option__divider" />
        <Accordion disableGutters className="setting-accordion">
          <AccordionSummary
            className="setting-accordion-title"
            expandIcon={<ExpandMoreIcon className="setting-accordion-icon" />}
          >
            <Typography className="setting-accordion-heading">Список</Typography>
          </AccordionSummary>
          <AccordionDetails className="setting-accordion-details">
            <IOSSwitch
              checked={autoListCreationToggle}
              onChange={handleListCreationDateToggleChange}
              sx={{ m: 1 }}
            />
            <Typography className="setting-accordion-text">
              При создании списка ставить текущую дату в название
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters className="setting-accordion">
          <AccordionSummary
            className="setting-accordion-title"
            expandIcon={<ExpandMoreIcon className="setting-accordion-icon" />}
          >
            <Typography className="setting-accordion-heading">Ещё</Typography>
          </AccordionSummary>
          <AccordionDetails className="setting-accordion-details">
            <IOSSwitch
              checked={installDialogToggle}
              onChange={handleInstallDialogToggleChange}
              sx={{ m: 1 }}
            />
            <Typography className="setting-accordion-text">
              Не показывать сообщение об установке приложения
            </Typography>
          </AccordionDetails>
        </Accordion>
        <ResetButton
          className="setting-options-reset"
          onClick={() => setIsResetDialogOpen(true)}
        />
      </Stack>
      <SettingProfile isOpen={isProfileOpen} onClose={handleProfileClose} />
      <CurtainPopup open={isLanguageOpen} onClose={() => setIsLanguageOpen(false)}>
        <FutureSettings
          onClose={() => setIsLanguageOpen(false)}
          onContinue={() => setIsLanguageOpen(true)}
          text="Скоро здесь будет возможность выбора языка интерфейса"
        />
      </CurtainPopup>
      <CurtainPopup open={isThemeOpen} onClose={() => setIsThemeOpen(false)}>
        <FutureSettings
          onClose={() => setIsThemeOpen(false)}
          onContinue={() => setIsThemeOpen(true)}
          text="Скоро здесь будет возможность выбора цветовой схемы приложения"
        />
      </CurtainPopup>
      <ResetDialog
        open={isResetDialogOpen}
        onClose={() => setIsResetDialogOpen(false)}
        onConfirm={handleReset}
        signedIn={signedIn}
        isOnline={isOnline}
        isLoading={isDataRestoring}
      />
    </Stack>
  );
};
