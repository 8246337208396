import { Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";

import { Alert } from "../Alert/Alert";
import "./MicAccessSnackbar.scss";

interface MicAccessSnackbarProps {
  open: boolean;
  onClose: () => void;
  onLinkClick?: () => void;
}

export const MicAccessSnackbar = (props: MicAccessSnackbarProps) => {
  const handleLinkClick = () => {
    if (props.onLinkClick) {
      props.onLinkClick();
    }
    props.onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      open={props.open}
      onClose={() => props.onClose()}
      autoHideDuration={5000}
      className="mic-access-snackbar"
    >
      <Alert type="warning" title="Нет доступа к микрофону">
        Разрешите подключение к микрофону через&nbsp;настройки телефона
        <Box className="mic-access-snackbar-link" onClick={handleLinkClick}>
          Перейти в настройки
        </Box>
      </Alert>
    </Snackbar>
  );
};
