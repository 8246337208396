export const AiStars = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M11.6954 2.43362C11.9093 1.85546 12.7271 1.85546 12.941 2.43362L13.6365 4.31323C14.0737 5.49474 15.0053 6.42629 16.1868 6.86349L18.0664 7.55901C18.6446 7.77295 18.6446 8.59069 18.0664 8.80462L16.1868 9.50014C15.0053 9.93734 14.0737 10.8689 13.6365 12.0504L12.941 13.93C12.7271 14.5082 11.9093 14.5082 11.6954 13.93L10.9999 12.0504C10.5627 10.8689 9.63113 9.93734 8.44962 9.50015L6.57 8.80462C5.99185 8.59069 5.99184 7.77295 6.57 7.55901L8.44962 6.86349C9.63113 6.42629 10.5627 5.49475 10.9999 4.31324L11.6954 2.43362Z"
        fill="white"
      />
      <path
        d="M4.93669 11.8157C5.06254 11.4756 5.54356 11.4756 5.66941 11.8157L6.07854 12.9213C6.33571 13.6163 6.88368 14.1643 7.57869 14.4215L8.68435 14.8306C9.02444 14.9565 9.02444 15.4375 8.68435 15.5633L7.57869 15.9725C6.88368 16.2296 6.33571 16.7776 6.07854 17.4726L5.66941 18.5783C5.54356 18.9184 5.06254 18.9184 4.93669 18.5783L4.52756 17.4726C4.27039 16.7776 3.72242 16.2296 3.02741 15.9725L1.92176 15.5633C1.58166 15.4375 1.58166 14.9565 1.92176 14.8306L3.02741 14.4215C3.72242 14.1643 4.27039 13.6163 4.52756 12.9213L4.93669 11.8157Z"
        fill="white"
      />
      <path
        d="M4.13502 2.85481C4.19795 2.68476 4.43846 2.68476 4.50138 2.85481L4.70595 3.40764C4.83453 3.75514 5.10852 4.02912 5.45602 4.15771L6.00885 4.36228C6.1789 4.4252 6.1789 4.66571 6.00885 4.72863L5.45602 4.9332C5.10852 5.06179 4.83453 5.33577 4.70595 5.68327L4.50138 6.2361C4.43846 6.40615 4.19795 6.40615 4.13502 6.2361L3.93046 5.68327C3.80187 5.33577 3.52789 5.06179 3.18038 4.9332L2.62756 4.72863C2.45751 4.66571 2.45751 4.4252 2.62756 4.36228L3.18038 4.15771C3.52789 4.02912 3.80187 3.75514 3.93046 3.40764L4.13502 2.85481Z"
        fill="white"
      />
    </svg>
  );
};
