import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import { StatusBar } from "@capacitor/status-bar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useMemo, useState } from "react";
import { usePageVisibility } from "react-page-visibility";
import { Outlet, Route, Routes } from "react-router-dom";

import "dayjs/locale/ru";
import config from "../appConfig/config";
import { AppInstalledSnackbar } from "../components/AppInstalledSnackbar/AppInstalledSnackbar";
import { AcceptConditionsDialog } from "../components/Dialogs/AcceptConditionDialog/AcceptConditionDialog";
import { InstallPwaDialog } from "../components/Dialogs/InstallPwaDialog/InstallPwaDialog";
import { PageLoading } from "../components/PageLoading/PageLoading";
import { YandexMetrika } from "../components/YandexMetrika/YandexMetrika";
import { useAppInstalled } from "../hooks/useAppInstalled";
import { useInstallDialog } from "../hooks/useInstallDialog";
import { useTelegramAppLogin } from "../hooks/useTelegramAppLogin";
import { useWindowSize } from "../hooks/useWindowSize";
import {
  enqueueSync,
  fetchInstallDialogToggle,
  fetchAutoListCreationToggle,
  runSync,
  setWindowSize,
  stopSync,
  fetchHasTriedAiVoice,
} from "../store/application/applicationSlice";
import { selectApplicationState } from "../store/application/selectors";
import { checkSignIn, getAndSaveUserProfile } from "../store/auth/authSlice";
import { selectAuthState } from "../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getDesignTokens } from "../theme";
import { Paths } from "../utils/constants";

import { AdminPage } from "./pages/AdminPage/AdminPage";
import { CopyItemsPage } from "./pages/CopyItemsPage/CopyItemsPage";
import { FutureFeaturesPage } from "./pages/FutureFeaturesPage/FutureFeaturesPage";
import { ListsPage } from "./pages/ListsPage/ListsPage";
import { LoginHomePage } from "./pages/LoginHomePage/LoginHomePage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { RecipeCreationPage } from "./pages/RecipeCreationPage/RecipeCreationPage";
import { RecipeEditingPage } from "./pages/RecipeEditingPage/RecipeEditingPage";
import { RecipesPage } from "./pages/RecipesPage/RecipesPage";
import { SettingsPage } from "./pages/SettingsPage/SettingsPage";
import { SignUpPage } from "./pages/SignUpPage/SignUpPage";
import { SupportPage } from "./pages/SupportPage/SupportPage";
import { TransferItemsPage } from "./pages/TransferItemsPage/TransferItemsPage";

export const App = () => {
  const dispatch = useAppDispatch();
  const { signedIn } = useAppSelector(selectAuthState);
  const { isNative } = useAppSelector(selectApplicationState);
  const isPageVisible = usePageVisibility();
  const { isInstallDialogOpen, setIsInstallDialogOpen, install } = useInstallDialog();
  const windowSize = useWindowSize();
  const [isAppInstalledSnackbarOpen, setIsAppInstalledSnackbarOpen] = useState(false);
  const { appInstalled } = useAppInstalled();
  const telegramAppLogin = useTelegramAppLogin();

  useEffect(() => {
    dispatch(checkSignIn());
    dispatch(fetchInstallDialogToggle());
    dispatch(fetchAutoListCreationToggle());
    dispatch(fetchHasTriedAiVoice());
  }, []);

  useEffect(() => {
    if (isNative) {
      StatusBar.setBackgroundColor({ color: "#08875D" }).catch(console.log);
    }
  }, [isNative]);

  useEffect(() => {
    if (signedIn) {
      dispatch(getAndSaveUserProfile());
    }
  }, [signedIn]);

  useEffect(() => {
    if (isPageVisible) {
      dispatch(runSync(config.SyncListsInterval));
      dispatch(enqueueSync());
    } else {
      dispatch(stopSync());
    }
  }, [isPageVisible]);

  useEffect(() => {
    dispatch(setWindowSize(windowSize));
  }, [windowSize]);

  useEffect(() => {
    if (appInstalled) {
      setIsAppInstalledSnackbarOpen(true);
    }
  }, [appInstalled]);

  const theme = useMemo(() => createTheme(getDesignTokens("light")), []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <CssBaseline />
        <GoogleOAuthProvider clientId={config.GoogleApiClientId}>
          <Routes>
            <Route path="/" element={<ListsPage />} />
            <Route path={Paths.SUPPORT} element={<SupportPage />} />
            <Route path={Paths.LOGIN_HOME} element={<LoginHomePage />} />
            <Route path={Paths.LOGIN} element={<LoginPage />} />
            <Route path={Paths.SIGN_UP} element={<SignUpPage />} />
            <Route path={Paths.FUTURE_FEATURES} element={<FutureFeaturesPage />} />
            <Route path={Paths.SETTINGS} element={<SettingsPage />} />
            {/*<Route path={Paths.RECIPES} element={<RecipesPage />} />*/}
            {/*<Route path={Paths.RECIPES} element={<Outlet />}>*/}
            {/*  <Route index element={<RecipesPage />} />*/}
            {/*  <Route path="create" element={<RecipeCreationPage />} />*/}
            {/*  <Route path="edit/:id" element={<RecipeEditingPage />} />*/}
            {/*</Route>*/}
            <Route path={Paths.ADMIN} element={<AdminPage />} />
            <Route path={Paths.TRANSFER_ITEMS} element={<TransferItemsPage />} />
            <Route path={Paths.COPY_ITEMS} element={<CopyItemsPage />} />
          </Routes>
          <InstallPwaDialog
            open={isInstallDialogOpen}
            onInstall={install}
            onClose={() => setIsInstallDialogOpen(false)}
          />
          <AppInstalledSnackbar
            open={isAppInstalledSnackbarOpen}
            onClose={() => setIsAppInstalledSnackbarOpen(false)}
          />
          {telegramAppLogin.isLoading && <PageLoading />}
        </GoogleOAuthProvider>
        <YandexMetrika code={config.YandexMetrikaCode} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
