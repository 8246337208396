import { ListItemCategoryInternalModel } from "../../internalStorage/models/ListItemCategoryInternalModel";
import { ListItemPromptInternalModel } from "../../internalStorage/models/ListItemPromptInternalModel";
import { InternalStorageCategoriesService } from "../../internalStorage/services/InternalStorageCategoriesService";
import { InternalStoragePromptsService } from "../../internalStorage/services/InternalStoragePromptsService";
import { ItemPromptDto, ItemPromptsService, UserItemPromptsService } from "../../openapi";
import { Syncer } from "../core/Syncer";

export class SyncPromptsService extends Syncer {
  private async saveServerPromptsToLocal(
    serverPrompts: ItemPromptDto[],
    localPromptsNames: Set<string>,
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<ListItemPromptInternalModel[]> {
    const promptsToSave: ListItemPromptInternalModel[] = serverPrompts
      .map((serverPrompt: ItemPromptDto): ListItemPromptInternalModel | undefined => {
        const isOnLocal: boolean = localPromptsNames.has(serverPrompt.name);
        const localCategory: ListItemCategoryInternalModel | undefined =
          localCategories.find(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.id === serverPrompt.categoryId,
          );
        if (!isOnLocal) {
          return {
            localId: serverPrompt.name,
            name: serverPrompt.name,
            order: serverPrompt.order,
            localCategoryId: localCategory?.localId ?? null,
            photoUrl: serverPrompt.photoUrl ?? "",
            unit: serverPrompt.unit ?? "",
            created: serverPrompt.created,
            updated: serverPrompt.updated,
          };
        }
      })
      .filter(
        (
          prompt: ListItemPromptInternalModel | undefined,
        ): prompt is ListItemPromptInternalModel => prompt !== undefined,
      );
    await InternalStoragePromptsService.addOrUpdatePrompts(promptsToSave);
    return promptsToSave;
  }

  private async postLocalPromptsToServer(
    serverPromptsNames: Set<string>,
    localPrompts: ListItemPromptInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localPrompts.map(
        async (localPrompt: ListItemPromptInternalModel): Promise<void> => {
          const isOnServer: boolean = serverPromptsNames.has(localPrompt.name);
          const localCategory: ListItemCategoryInternalModel | undefined =
            localCategories.find(
              (localCategory: ListItemCategoryInternalModel): boolean =>
                localCategory.localId === localPrompt.localCategoryId,
            );

          if (!isOnServer) {
            await UserItemPromptsService.postApiUserItemPrompts({
              name: localPrompt.name,
              order: localPrompt.order,
              categoryId: localCategory?.id ?? null,
              photoUrl: localPrompt.photoUrl,
              unit: localPrompt.unit,
            });
          }
        },
      ),
    );
  }

  private async updateLocalPrompts(
    serverPrompts: ItemPromptDto[],
    localPrompts: ListItemPromptInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<ListItemPromptInternalModel[]> {
    const localPromptsToUpdate: ListItemPromptInternalModel[] = localPrompts
      .map(
        (
          localPrompt: ListItemPromptInternalModel,
        ): ListItemPromptInternalModel | undefined => {
          const serverPrompt: ItemPromptDto | undefined = serverPrompts.find(
            (serverPrompt: ItemPromptDto): boolean =>
              serverPrompt.name === localPrompt.name,
          );
          if (!serverPrompt?.updated || !localPrompt.updated) {
            return;
          }
          const localCategory: ListItemCategoryInternalModel | undefined =
            localCategories.find(
              (localCategory: ListItemCategoryInternalModel): boolean =>
                localCategory.id === serverPrompt.categoryId,
            );
          const serverPromptUpdated: number = new Date(serverPrompt.updated).valueOf();
          const localPromptUpdated: number = new Date(localPrompt.updated).valueOf();
          const isServerPromptNewer: boolean =
            serverPromptUpdated - localPromptUpdated > 0;

          if (isServerPromptNewer) {
            return {
              localId: localPrompt.localId,
              name: serverPrompt.name,
              order: serverPrompt.order,
              localCategoryId: localCategory?.localId ?? null,
              photoUrl: serverPrompt.photoUrl ?? "",
              unit: serverPrompt.unit ?? "",
              created: serverPrompt.created,
              updated: serverPrompt.updated,
            };
          }
        },
      )
      .filter(
        (
          prompt: ListItemPromptInternalModel | undefined,
        ): prompt is ListItemPromptInternalModel => prompt !== undefined,
      );
    await InternalStoragePromptsService.addOrUpdatePrompts(localPromptsToUpdate);
    return localPromptsToUpdate;
  }

  private async updateServerPrompts(
    serverPrompts: ItemPromptDto[],
    localPrompts: ListItemPromptInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverPrompts.map(async (serverPrompt: ItemPromptDto): Promise<void> => {
        const localPrompt: ListItemPromptInternalModel | undefined = localPrompts.find(
          (localPrompt: ListItemPromptInternalModel): boolean =>
            localPrompt.name === serverPrompt.name,
        );
        if (!localPrompt?.updated || !localPrompt.updated) {
          return;
        }
        const localCategory: ListItemCategoryInternalModel | undefined =
          localCategories.find(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.localId === localPrompt.localCategoryId,
          );
        const serverPromptUpdated: number = new Date(serverPrompt.updated).valueOf();
        const localPromptUpdated: number = new Date(localPrompt.updated).valueOf();
        const isLocalPromptNewer: boolean = localPromptUpdated - serverPromptUpdated > 0;

        if (isLocalPromptNewer) {
          const promptData = {
            name: localPrompt.name,
            order: localPrompt.order,
            categoryId: localCategory?.id ?? null,
            photoUrl: localPrompt.photoUrl,
            unit: localPrompt.unit,
          };
          if (serverPrompt.isCustom) {
            await UserItemPromptsService.putApiUserItemPrompts(promptData);
          } else {
            await UserItemPromptsService.postApiUserItemPrompts(promptData);
          }
        }
      }),
    );
  }

  private async syncAnonPrompts() {
    const localPrompts: ListItemPromptInternalModel[] =
      await InternalStoragePromptsService.getPrompts();
    if (!localPrompts.length) {
      const serverPrompts: ItemPromptDto[] = await ItemPromptsService.getApiItemPrompts();
      const localPromptsNames: Set<string> = new Set(
        localPrompts.map((localPrompt: ListItemPromptInternalModel) => localPrompt.name),
      );
      const localCategories: ListItemCategoryInternalModel[] =
        await InternalStorageCategoriesService.getCategories();
      await this.saveServerPromptsToLocal(
        serverPrompts,
        localPromptsNames,
        localCategories,
      );
      await this.afterSyncCallback();
    }
  }

  private async syncUserPrompts() {
    const beforeSyncTime = new Date().toISOString();
    const serverPrompts: ItemPromptDto[] =
      await UserItemPromptsService.getApiUserItemPrompts();
    const serverPromptsNames: Set<string> = new Set(
      serverPrompts.map((serverPrompt: ItemPromptDto) => serverPrompt.name),
    );
    const localPrompts: ListItemPromptInternalModel[] =
      await InternalStoragePromptsService.getPrompts();
    const localPromptsNames: Set<string> = new Set(
      localPrompts.map((localPrompt: ListItemPromptInternalModel) => localPrompt.name),
    );
    const localCategories: ListItemCategoryInternalModel[] =
      await InternalStorageCategoriesService.getCategories();
    const savedPromptsToLocal = await this.saveServerPromptsToLocal(
      serverPrompts,
      localPromptsNames,
      localCategories,
    );
    await this.postLocalPromptsToServer(
      serverPromptsNames,
      localPrompts,
      localCategories,
    );
    const updatedLocalPrompts = await this.updateLocalPrompts(
      serverPrompts,
      localPrompts,
      localCategories,
    );
    await this.updateServerPrompts(serverPrompts, localPrompts, localCategories);
    if (savedPromptsToLocal.length || updatedLocalPrompts.length) {
      await this.afterSyncCallback();
    }
    await this.changesTracker.setLastSync(beforeSyncTime);
  }

  public async sync(signedId: boolean): Promise<void> {
    if (!signedId) {
      console.log("Anonymous prompts synchronization is running...");
      console.time("Anonymous prompts synced");
      await this.syncAnonPrompts();
      console.timeEnd("Anonymous prompts synced");
    } else {
      console.log("Prompts synchronization is running...");
      console.time("Prompts synced");
      await this.syncUserPrompts();
      console.timeEnd("Prompts synced");
    }
  }
}
