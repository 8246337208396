import { Checkbox, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

import "./ListItemsSelection.scss";
import { CheckboxSquare } from "../../assets/Icons/CheckboxSquare";
import { CheckboxSquareChecked } from "../../assets/Icons/CheckboxSquareChecked";
import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";

export interface ListItemsSelectionProps {
  items: ListItemInternalModel[];
  selectedItems: ListItemInternalModel[];
  onChange: (selectedItems: ListItemInternalModel[]) => void;
}

export const ListItemsSelection = (props: ListItemsSelectionProps) => {
  const toggleSelection = (toggledItem: ListItemInternalModel) => {
    const newSelected = props.selectedItems.some(
      (item) => item.localId === toggledItem.localId,
    )
      ? props.selectedItems.filter((item) => item.localId !== toggledItem.localId)
      : [...props.selectedItems, toggledItem];

    props.onChange(newSelected);
  };

  const selectAll = () => {
    const newSelected =
      props.selectedItems.length === props.items.length ? [] : [...props.items];
    props.onChange(newSelected);
  };

  return (
    <List className="list-items-selection">
      <ListItem className="list-items-selection-item" onClick={selectAll}>
        <Checkbox
          className="list-items-selection-item-icon"
          checked={props.selectedItems.length === props.items.length}
          indeterminate={
            props.selectedItems.length > 0 &&
            props.selectedItems.length < props.items.length
          }
          checkedIcon={<CheckboxSquareChecked />}
          icon={<CheckboxSquare />}
          indeterminateIcon={<CheckboxSquare />}
        />
        <ListItemText
          className="list-items-selection-item-text"
          primary={<Typography fontWeight="bold">Выбрать все</Typography>}
        />
      </ListItem>
      {props.items.map((item) => (
        <ListItem
          className="list-items-selection-item"
          key={item.localId}
          onClick={() => toggleSelection(item)}
        >
          <Checkbox
            className="list-items-selection-item-icon"
            checkedIcon={<CheckboxSquareChecked />}
            icon={<CheckboxSquare />}
            checked={props.selectedItems.some((i) => i.localId === item.localId)}
          />
          <ListItemText className="list-items-selection-item-text" primary={item.name} />
        </ListItem>
      ))}
    </List>
  );
};
