import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { Alert } from "../Alert/Alert";
import { CheckboxField } from "../CheckboxField/CheckboxField";
import "./FutureFeaturesForm.scss";
import { TextArea } from "../TextArea/TextArea";

interface FutureFeaturesFormProps {
  onSubmit: (features: string[]) => void;
}

export const FUTURE_FEATURES = [
  "Возможность рассчитывать траты за период времени (месяц, неделя и т.д.)",
  "Возможность составлять рецепты и добавлять продукты сразу из рецепта в список",
  "Устанавливать напоминание о покупке чего‑то и показывать пуш при наступлении времени",
  "Показывать пуш-уведомления при появлении нового элемента списка",
  "Заполнять список через голосовой помощник Алиса",
  "Устанавливать локацию у списка и получать пуш-уведомление, когда рядом",
];
export const MAX_FEATURES = 2;

export const FutureFeaturesForm = (props: FutureFeaturesFormProps) => {
  const [checkedFeatures, setCheckedFeatures] = useState<string[]>([]);
  const [isCustomFeature, setIsCustomFeature] = useState(false);
  const [customFeature, setCustomFeature] = useState("");

  const handleSubmit = () => {
    if (isCustomFeature) {
      props.onSubmit([...checkedFeatures, customFeature]);
    } else {
      props.onSubmit([...checkedFeatures]);
    }
  };

  const handleCheckboxClick = (feature: string) => {
    if (!checkedFeatures.includes(feature)) {
      setCheckedFeatures([...checkedFeatures, feature]);
    } else {
      setCheckedFeatures(checkedFeatures.filter((f) => f !== feature));
    }
  };

  const handleCustomFeatureClick = () => {
    setIsCustomFeature(!isCustomFeature);
  };

  return (
    <Box className="future-features-form">
      <Alert type="info">
        Выберите две функции приложения, которые мы разработаем в самое ближайшее время
      </Alert>
      <Box className="future-features-form-options">
        {FUTURE_FEATURES.map((feature: string, index) => (
          <CheckboxField
            key={index}
            checked={checkedFeatures.includes(feature)}
            onClick={() => handleCheckboxClick(feature)}
            disabled={
              checkedFeatures.length >= MAX_FEATURES && !checkedFeatures.includes(feature)
            }
          >
            {feature}
          </CheckboxField>
        ))}
        <Accordion
          className="future-features-form-accordion"
          disableGutters
          expanded={isCustomFeature}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={handleCustomFeatureClick}
          >
            <CheckboxField checked={isCustomFeature}>Другое</CheckboxField>
          </AccordionSummary>
          <AccordionDetails>
            <TextArea
              autoFocus={true}
              placeholder="Напишите желаемую функцию"
              value={customFeature}
              onChange={(e) => setCustomFeature(e.target.value.slice(0, 500))}
            />
            <Typography className="future-features-form-counter">
              Введено {customFeature.length} из 500 символов
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Button
        variant="contained"
        className="future-features-form-button"
        onClick={handleSubmit}
        disabled={checkedFeatures.length < MAX_FEATURES && !customFeature.length}
      >
        Отправить
      </Button>
    </Box>
  );
};
