import { Capacitor } from "@capacitor/core";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { DbNames } from "../../services/internalStorage/core/DbNames";
import { InternalStorageCommonService } from "../../services/internalStorage/services/InternalStorageCommonService";
import { ChangesTracker } from "../../services/sync/core/ChangesTracker";
import { SyncCategoriesService } from "../../services/sync/services/SyncCategoriesService";
import { SyncListsService } from "../../services/sync/services/SyncListsService";
import { SyncPromptsService } from "../../services/sync/services/SyncPromptsService";
import { SyncService } from "../../services/sync/services/SyncService";
import { COMMON_STORE_KEYS, LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { deleteAllCategories, fetchCategories } from "../categories/categoriesSlice";
import { deleteAllListsAndItems, fetchListItems, fetchLists } from "../lists/listsSlice";
import { deleteAllPrompts, fetchPrompts } from "../prompts/promptsSlice";
import { RootState } from "../store";

export type ApplicationSliceType = {
  isOnline: boolean;
  installDialogToggle: boolean;
  autoListCreationToggle: boolean;
  firstVisitDate: string;
  windowSize: { width: number; height: number };
  isNative: boolean;
  telegramInitData: string;
  telegramInitDataUnsafe: {
    start_param?: string;
  };
  hasTriedAiVoice: boolean;
};

const initialState: ApplicationSliceType = {
  isOnline: true,
  installDialogToggle: false,
  autoListCreationToggle: false,
  firstVisitDate: localStorage.getItem(LOCAL_STORAGE_KEYS.FIRST_VISIT_DATE) ?? "",
  windowSize: { width: window.innerWidth, height: window.innerHeight },
  isNative: Capacitor.isNativePlatform(),
  telegramInitData: window.Telegram?.WebApp?.initData ?? "",
  telegramInitDataUnsafe: window?.Telegram?.WebApp?.initDataUnsafe ?? {},
  hasTriedAiVoice: false,
};

export const toggleIsOnline = createAsyncThunk<boolean, boolean>(
  "application/toggleIsOnline",
  async (isOnline) => isOnline,
);

export const enqueueSync = createAsyncThunk<void>("application/enqueueSync", async () => {
  try {
    await SyncService.enqueue();
  } catch (e) {
    console.log(e);
  }
});

export const runSync = createAsyncThunk<void, number>(
  "application/runSync",
  async (interval, { dispatch, getState }) => {
    try {
      const checkSignIn = () => !!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
      const setIsOnline = (isOnline: boolean) => {
        const state = getState() as RootState;
        if (state.application.isOnline !== isOnline) {
          dispatch(toggleIsOnline(isOnline));
        }
      };
      const listsSyncer = new SyncListsService(new ChangesTracker(0), async () => {
        await dispatch(fetchLists());
        const state = getState() as RootState;
        if (state.lists.selectedList !== null) {
          await dispatch(fetchListItems(state.lists.selectedList.localId));
        }
      });
      const promptsSyncer = new SyncPromptsService(new ChangesTracker(1), async () => {
        await dispatch(fetchPrompts());
      });
      const categoriesSyncer = new SyncCategoriesService(
        new ChangesTracker(2),
        async () => {
          await dispatch(fetchCategories());
        },
      );
      SyncService.start(interval, checkSignIn, setIsOnline, [
        categoriesSyncer,
        listsSyncer,
        promptsSyncer,
      ]);
    } catch (e) {
      console.log(e);
    }
  },
);

export const stopSync = createAsyncThunk<void>("application/stopSync", async () => {
  try {
    SyncService.stop();
  } catch (e) {
    console.log(e);
  }
});

export const fetchInstallDialogToggle = createAsyncThunk<boolean | null, void>(
  "application/fetchInstallDialogToggle",
  async () => {
    try {
      return !!(await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.INSTALL_DIALOG_TOGGLE,
      ));
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const fetchAutoListCreationToggle = createAsyncThunk<boolean | null, void>(
  "application/fetchAutoListCreationToggle",
  async () => {
    try {
      const isTurnedOn = await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.AUTO_LIST_CREATION_TOGGLE,
      );
      if (typeof isTurnedOn !== "boolean") {
        await InternalStorageCommonService.addOrUpdateValue(
          COMMON_STORE_KEYS.AUTO_LIST_CREATION_TOGGLE,
          true,
        );
        return true;
      }
      return isTurnedOn;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const setAutoListCreationToggle = createAsyncThunk<boolean | null, boolean>(
  "application/setAutoListCreationToggle",
  async (checked) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.AUTO_LIST_CREATION_TOGGLE,
        checked,
      );
      return checked;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const setInstallDialogToggle = createAsyncThunk<boolean | null, boolean>(
  "application/setInstallDialogToggle",
  async (checked) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.INSTALL_DIALOG_TOGGLE,
        checked,
      );
      return checked;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const fetchInstallDialogLastShow = createAsyncThunk<number | null, void>(
  "application/fetchInstallDialogLastShow",
  async () => {
    try {
      return (
        ((await InternalStorageCommonService.getValue(
          COMMON_STORE_KEYS.INSTALL_DIALOG_LAST_SHOW,
        )) as number | undefined) ?? 0
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const setInstallDialogLastShow = createAsyncThunk<void, number>(
  "application/setInstallDialogLastShow",
  async (lastShowTimestamp) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.INSTALL_DIALOG_LAST_SHOW,
        lastShowTimestamp,
      );
    } catch (e) {
      console.log(e);
    }
  },
);

export const setShowRegistationLastShow = createAsyncThunk<void, number>(
  "application/setShowRegistationLastShow",
  async (lastShow) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.SHOW_REGISTRATION_LAST_SHOW,
        lastShow,
      );
    } catch (e) {
      console.log(e);
    }
  },
);

export const fetchShowRegistrationLastShow = createAsyncThunk<number | null, void>(
  "application/fetchShowRegistrationLastShow",
  async () => {
    try {
      return (
        ((await InternalStorageCommonService.getValue(
          COMMON_STORE_KEYS.SHOW_REGISTRATION_LAST_SHOW,
        )) as number | undefined) ?? 0
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const fetchHasTriedAiVoice = createAsyncThunk<boolean, void>(
  "application/fetchHasTriedAiVoice",
  async () => {
    try {
      return (
        !!(await InternalStorageCommonService.getValue(
          COMMON_STORE_KEYS.HAS_TRIED_AI_VOICE,
        )) ||
        !!(await InternalStorageCommonService.getValue(
          COMMON_STORE_KEYS.HAS_TRIED_AI_VOICE,
          DbNames.DEFAULT_USER_NAME,
        ))
      );
    } catch (e) {
      console.log(e);
      return false;
    }
  },
);

export const setHasTriedAiVoice = createAsyncThunk<boolean, boolean>(
  "application/setHasTriedAiVoice",
  async (tried) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.HAS_TRIED_AI_VOICE,
        tried,
      );
      return tried;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
);

export const restoreData = createAsyncThunk<void, void>(
  "application/restoreData",
  async (_, { dispatch }) => {
    try {
      await Promise.all([
        dispatch(deleteAllListsAndItems()),
        dispatch(deleteAllCategories()),
        dispatch(deleteAllPrompts()),
      ]);
      await SyncService.enqueue();
    } catch (e) {
      console.log(e);
    }
  },
);

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setFirstVisitDate: (state, action: { payload: string }) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.FIRST_VISIT_DATE, action.payload);
      state.firstVisitDate = action.payload;
    },
    setWindowSize: (state, action: { payload: { width: number; height: number } }) => {
      state.windowSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(toggleIsOnline.fulfilled, (state, action) => {
      state.isOnline = action.payload;
    });
    builder.addCase(fetchInstallDialogToggle.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.installDialogToggle = action.payload;
      }
    });
    builder.addCase(setInstallDialogToggle.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.installDialogToggle = action.payload;
      }
    });
    builder.addCase(fetchAutoListCreationToggle.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.autoListCreationToggle = action.payload;
      }
    });
    builder.addCase(setAutoListCreationToggle.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.autoListCreationToggle = action.payload;
      }
    });
    builder.addCase(fetchHasTriedAiVoice.fulfilled, (state, action) => {
      state.hasTriedAiVoice = action.payload;
    });
    builder.addCase(setHasTriedAiVoice.fulfilled, (state, action) => {
      state.hasTriedAiVoice = action.payload;
    });
  },
});

export const { setFirstVisitDate, setWindowSize } = applicationSlice.actions;
