import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { InternalStorageCategoriesService } from "../../services/internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageCommonService } from "../../services/internalStorage/services/InternalStorageCommonService";
import { InternalStorageListsService } from "../../services/internalStorage/services/InternalStorageListsService";
import { InternalStoragePromptsService } from "../../services/internalStorage/services/InternalStoragePromptsService";
import {
  AddRecipeRequest,
  FileService,
  RecipeDetailedDto,
  RecipeItemDetailedDto,
  RecipeShortenedDto,
  RecipesService,
  UpdateRecipeRequest,
} from "../../services/openapi";
import { SyncService } from "../../services/sync/services/SyncService";
import { COMMON_STORE_KEYS, DEFAULT_UNIT } from "../../utils/constants";
import { getUniqueId } from "../../utils/dateTimeUtil";
import normalizeText from "../../utils/normalizeText";

export type RecipesStateType = {
  recipes: RecipeShortenedDto[];
  areRecipesLoading: boolean;
  detailedRecipe: RecipeDetailedDto | null;
  isDetailedRecipeLoading: boolean;
  isRecipeUpdating: boolean;
  selectedRecipes: number[];
};

const initialState: RecipesStateType = {
  recipes: [],
  areRecipesLoading: false,
  detailedRecipe: null,
  isDetailedRecipeLoading: false,
  isRecipeUpdating: false,
  selectedRecipes: [],
};

export const fetchRecipes = createAsyncThunk<
  RecipeShortenedDto[] | null,
  string | undefined
>("recipes/fetchRecipes", async (nameFilter) => {
  try {
    return await RecipesService.getApiRecipes(nameFilter);
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const fetchRecipe = createAsyncThunk<RecipeDetailedDto | null, number>(
  "recipes/fetchRecipe",
  async (id) => {
    try {
      return await RecipesService.getApiRecipes1(id);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const createRecipe = createAsyncThunk<void, AddRecipeRequest>(
  "recipes/createRecipe",
  async (recipe, { dispatch }) => {
    try {
      await RecipesService.postApiRecipes(recipe);
      dispatch(fetchRecipes());
    } catch (e) {
      console.log(e);
    }
  },
);

export const editRecipe = createAsyncThunk<
  void,
  { id: number; newData: UpdateRecipeRequest }
>("recipes/editRecipe", async ({ id, newData }, { dispatch }) => {
  try {
    await RecipesService.putApiRecipes(id, newData);
    dispatch(fetchRecipes());
    dispatch(fetchRecipe(id));
  } catch (e) {
    console.log(e);
  }
});

export const deleteRecipe = createAsyncThunk<number | null, number>(
  "recipes/createRecipe",
  async (recipeId) => {
    try {
      await RecipesService.deleteApiRecipes(recipeId);
      return recipeId;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const uploadRecipeImage = createAsyncThunk<string | null, File>(
  "recipes/uploadRecipeImage",
  async (file) => {
    try {
      return await FileService.postApiFilesUpload({
        file: file,
      });
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const addRecipesToList = createAsyncThunk<
  void,
  { recipesIds: number[]; listLocalId: number }
>("recipes/addRecipesToLists", async ({ recipesIds, listLocalId }) => {
  try {
    if (!recipesIds?.length || !listLocalId) {
      return;
    }
    const [recipes, listItems, categories, prompts] = await Promise.all([
      Promise.all(recipesIds.map((id) => RecipesService.getApiRecipes1(id))),
      InternalStorageListsService.getListItems(listLocalId),
      InternalStorageCategoriesService.getCategories(),
      InternalStoragePromptsService.getPrompts(),
    ]);
    const recipeItems: RecipeItemDetailedDto[] = recipes.flatMap<RecipeItemDetailedDto>(
      (recipe) => recipe.recipeItems ?? [],
    );
    const recipeItemsNameQuantityMap: Map<string, number> = new Map();
    recipeItems.forEach((recipeItem) => {
      const recipeItemName = normalizeText(recipeItem.name);
      const existingQuantity = recipeItemsNameQuantityMap.get(recipeItemName) ?? 0;
      recipeItemsNameQuantityMap.set(
        recipeItemName,
        existingQuantity + recipeItem.quantity,
      );
    });
    await Promise.all(
      Array.from(recipeItemsNameQuantityMap).map(async ([name, quantity]) => {
        const existingItem = listItems.find(
          (listItem) => normalizeText(listItem.name) === normalizeText(name),
        );
        if (existingItem) {
          await InternalStorageListsService.updateListItem({
            localId: existingItem.localId,
            quantity: existingItem.quantity + quantity,
          });
        } else {
          let prompt = prompts.find((p) => normalizeText(p.name) === normalizeText(name));
          if (!prompt) {
            prompt = prompts.find((p) =>
              normalizeText(name).split(" ").includes(normalizeText(p.name)),
            );
          }
          const category = categories.find((c) => c.localId === prompt?.localCategoryId);
          await InternalStorageListsService.createListItem({
            name,
            id: null,
            localId: getUniqueId(),
            localListId: listLocalId,
            isCompleted: false,
            quantity,
            unit: DEFAULT_UNIT,
            photoUrl: "",
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            order: 0,
            deleted: null,
            localCategory: category ?? null,
          });
        }
      }),
    );
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      new Date().toISOString(),
    );
    SyncService.enqueue().catch(console.log);
  } catch (e) {
    console.log(e);
  }
});

export const recipesSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {
    setSelectedRecipes: (state, action: { payload: number[] }) => {
      state.selectedRecipes = action.payload;
    },
    toggleRecipe(state, action: { payload: { recipeId: number } }) {
      const recipeId = action.payload.recipeId;
      if (state.selectedRecipes.includes(recipeId)) {
        state.selectedRecipes = state.selectedRecipes.filter((id) => id !== recipeId);
      } else {
        state.selectedRecipes.push(recipeId);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecipes.pending, (state) => {
      state.areRecipesLoading = true;
    });
    builder.addCase(fetchRecipes.fulfilled, (state, action) => {
      if (action.payload) {
        state.recipes = action.payload;
      }
      state.areRecipesLoading = false;
    });
    builder.addCase(fetchRecipe.pending, (state) => {
      state.isDetailedRecipeLoading = true;
    });
    builder.addCase(fetchRecipe.fulfilled, (state, action) => {
      if (action.payload) {
        state.detailedRecipe = action.payload;
      }
      state.isDetailedRecipeLoading = false;
    });
    builder.addCase(editRecipe.pending, (state) => {
      state.isRecipeUpdating = true;
    });
    builder.addCase(editRecipe.fulfilled, (state) => {
      state.isRecipeUpdating = false;
    });
    builder.addCase(deleteRecipe.fulfilled, (state, action) => {
      if (action.payload) {
        state.recipes = state.recipes.filter((recipe) => recipe.id !== action.payload);
      }
    });
  },
});

export const { setSelectedRecipes, toggleRecipe } = recipesSlice.actions;
