import { Capacitor } from "@capacitor/core";
import { SpeechRecognition } from "@capacitor-community/speech-recognition";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { useState, useEffect } from "react";

import { LOCAL_STORAGE_KEYS } from "../utils/constants";

const checkIsSupported = async () => {
  if (Capacitor.isNativePlatform()) {
    const { available } = await SpeechRecognition.available();
    return available;
  }
  return false;
};

const checkPermission = async () => {
  const permissionStatus = await SpeechRecognition.checkPermissions();
  return permissionStatus.speechRecognition === "granted";
};

const requestPermission = async (): Promise<{
  permissionGranted: boolean;
  currentAttempt: number;
}> => {
  const storedAttempt = localStorage.getItem(LOCAL_STORAGE_KEYS.MIC_PERMISSION_ATTEMPTS);
  const currentAttempt = +(storedAttempt ?? "0") + 1;
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.MIC_PERMISSION_ATTEMPTS,
    currentAttempt.toString(),
  );
  let permissionGranted = false;
  if (currentAttempt <= 2) {
    const requestStatus = await SpeechRecognition.requestPermissions();
    permissionGranted = requestStatus.speechRecognition === "granted";
  }
  return { permissionGranted, currentAttempt };
};

export const useNativeSpeechRecognition = (): {
  transcript: string;
  resetTranscript: () => void;
  isListening: boolean;
  startListening: (options?: { language?: string }) => Promise<void>;
  stopListening: () => Promise<void>;
  isAvailable: boolean;
  permissionAttempts: number;
  openSettings: () => Promise<void>;
} => {
  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [permissionAttempts, setPermissionAttempts] = useState(0);

  useEffect(() => {
    checkIsSupported().then(async (isSupported) => {
      if (isSupported) {
        setIsAvailable(true);
      }
    });
    SpeechRecognition.addListener("partialResults", ({ matches }) => {
      if (matches.length) {
        setTranscript(matches[0]);
      }
    }).catch(console.log);
    SpeechRecognition.addListener("listeningState", ({ status }) => {
      if (status === "stopped") {
        setTimeout(() => {
          setIsListening(false);
        }, 500);
      }
    }).catch(console.log);
    return () => {
      SpeechRecognition.removeAllListeners().catch(console.log);
    };
  }, []);

  const startListening = async (options?: { language?: string }) => {
    if (!isAvailable) {
      return;
    }
    const hasPermission = await checkPermission();
    if (!hasPermission) {
      const { permissionGranted, currentAttempt } = await requestPermission();
      if (!permissionGranted) {
        setPermissionAttempts(currentAttempt);
        return;
      }
    }
    try {
      setTranscript("");
      setIsListening(true);
      await SpeechRecognition.start({
        language: options?.language || "ru-RU",
        partialResults: true,
        popup: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const stopListening = async () => {
    setIsListening(false);
    await SpeechRecognition.stop();
  };

  const resetTranscript = () => {
    setTranscript("");
  };

  const openSettings = async () => {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  return {
    transcript,
    resetTranscript,
    isListening,
    startListening,
    stopListening,
    isAvailable,
    permissionAttempts,
    openSettings,
  };
};
