import { Capacitor } from "@capacitor/core";
import { SocialLogin } from "@capgo/capacitor-social-login";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";

import config from "../appConfig/config";

interface UseGoogleAuthOptions {
  onSuccess?: (authCode: string, isNative: boolean) => Promise<void>;
  onError?: (error: unknown) => void;
}

type UseGoogleAuthType = (options: UseGoogleAuthOptions) => () => void;

export const useNativeGoogleAuth = ({ onSuccess, onError }: UseGoogleAuthOptions) => {
  useEffect(() => {
    SocialLogin.initialize({
      google: {
        webClientId: config.GoogleApiClientId,
        mode: "offline",
      },
    }).catch(console.log);
  }, []);

  return async () => {
    try {
      const { result } = await SocialLogin.login({
        provider: "google",
        options: {
          forceRefreshToken: true,
        },
      });
      if (onSuccess && result.responseType === "offline") {
        await onSuccess(result.serverAuthCode, true);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };
};

export const useWebGoogleAuth = ({ onSuccess, onError }: UseGoogleAuthOptions) => {
  return useGoogleLogin({
    onSuccess: async (codeResponse) => {
      if (onSuccess) {
        await onSuccess(codeResponse.code, false);
      }
    },
    onError,
    flow: "auth-code",
  });
};

export const useGoogleAuth: UseGoogleAuthType = Capacitor.isNativePlatform()
  ? useNativeGoogleAuth
  : useWebGoogleAuth;
