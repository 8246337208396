import { IconButton, Tab, Tabs } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowBack } from "../../../assets/Icons/ArrowBack";
import { AdminActivityChart } from "../../../components/AdminActivityChart/AdminActivityChart";
import { PageHeading } from "../../../components/PageHeading/PageHeading";
import { PageLoading } from "../../../components/PageLoading/PageLoading";
import { TabPanel } from "../../../components/TabPanel/TabPanel";
import { fetchUsersActivities, fetchUsersCreated } from "../../../store/admin/adminSlice";
import {
  selectUsersActivities,
  selectUsersCreated,
} from "../../../store/admin/selectors";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { USERS_ACTIVITIES_LAST_DAYS } from "../../../utils/constants";
import { getLastDaysPeriod } from "../../../utils/dateTimeUtil";
import "./AdminPage.scss";

export const AdminPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, signedIn } = useAppSelector(selectAuthState);
  const usersActivities = useAppSelector(selectUsersActivities);
  const usersCreated = useAppSelector(selectUsersCreated);
  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    const period = getLastDaysPeriod(USERS_ACTIVITIES_LAST_DAYS);
    dispatch(fetchUsersActivities(period));
    dispatch(fetchUsersCreated(period));
  }, []);

  useEffect(() => {
    if (!signedIn || user.isAdmin === false) {
      navigate("/");
    }
  }, [user, signedIn]);

  if (!signedIn || !user.isAdmin) {
    return <PageLoading />;
  }

  return (
    <Stack className="admin-page">
      <PageHeading
        leftAdornment={
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        }
      >
        Статистика использования
      </PageHeading>
      <Tabs
        className="admin-page-tabs"
        value={tab}
        onChange={(_, newValue) => setTab(newValue)}
      >
        <Tab label="Посещаемость" />
        <Tab label="Регистрации" />
      </Tabs>
      <Stack className="admin-page-body">
        <TabPanel value={tab} index={0}>
          <AdminActivityChart
            usersActivities={usersActivities}
            onPeriodChange={(startDate, endDate) => {
              dispatch(fetchUsersActivities({ startDate, endDate }));
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <AdminActivityChart
            usersActivities={usersCreated}
            onPeriodChange={(startDate, endDate) => {
              dispatch(fetchUsersCreated({ startDate, endDate }));
            }}
          />
        </TabPanel>
      </Stack>
    </Stack>
  );
};
