import "./KeyboardButton.scss";
import { Button } from "@mui/material";
import { ReactNode } from "react";

interface KeyboardButtonProps {
  onClick?: () => void;
  children?: ReactNode;
  type?: "success" | "info" | "danger";
}

export const KeyboardButton = (props: KeyboardButtonProps) => {
  return (
    <Button
      className={`keyboard-button ${props.type ? `keyboard-button_${props.type}` : ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
