import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import config from "../../../appConfig/config";
import { AlertSnackbar } from "../../../components/AlertSnackbar/AlertSnackbar";
import { AcceptConditionsDialog } from "../../../components/Dialogs/AcceptConditionDialog/AcceptConditionDialog";
import { LoginOptions } from "../../../components/LoginOptions/LoginOptions";
import { LoginQuestion } from "../../../components/LoginQuestion/LoginQuestion";
import { LoginTitle } from "../../../components/LoginTitle/LoginTitle";
import { PageLoading } from "../../../components/PageLoading/PageLoading";
import SwipeableTextMobileStepper from "../../../components/Slider/Slider";
import "./LoginHomePage.scss";
import { useGoogleAuth } from "../../../hooks/useGoogleAuth";
import { login, loginByGoogle } from "../../../store/auth/authSlice";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Paths } from "../../../utils/constants";

export const LoginHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { signedIn } = useAppSelector(selectAuthState);
  const [isGoogleLoginError, setIsGoogleLoginError] = useState(false);
  const [isGoogleLoginLoading, setIsGoogleLoginLoading] = useState(false);
  const [isConditionsDialogOpened, setIsConditionsDialogOpened] = useState(false);
  const [tokenByGoogle, setTokenByGoogle] = useState<string | null>(null);

  useEffect(() => {
    if (signedIn) {
      navigate("/");
    }
  }, [signedIn, navigate]);

  const handleGoogleLogin = useGoogleAuth({
    onSuccess: async (authCode, isNative) => {
      setIsGoogleLoginLoading(true);
      const loginAction = await dispatch(loginByGoogle({ authCode, isNative }));
      setIsGoogleLoginLoading(false);
      if (!loginAction.payload) {
        setIsGoogleLoginError(true);
        return;
      }
      const { isNew, token } = loginAction.payload as { token: string; isNew: boolean };
      if (isNew) {
        setTokenByGoogle(token);
        setIsConditionsDialogOpened(true);
      } else {
        await dispatch(login(token));
      }
    },
    onError: async () => {
      setIsGoogleLoginError(true);
    },
  });

  const handleAcceptConditions = () => {
    if (tokenByGoogle) {
      dispatch(login(tokenByGoogle));
    }
    setTokenByGoogle(null);
    setIsConditionsDialogOpened(false);
  };

  if (signedIn) {
    return null;
  }

  return (
    <Stack className="login-home-container">
      <LoginTitle text="Добро пожаловать!  👋" onBackClick={() => navigate(-1)} />
      <Stack alignItems={"center"} position={"relative"} width={"100%"}>
        <SwipeableTextMobileStepper />
        <LoginOptions
          onLoginGoogle={() => handleGoogleLogin()}
          onLoginEmail={() => navigate(Paths.LOGIN)}
        />
        <LoginQuestion
          question="Нет аккаунта?"
          linkText="Регистрация"
          linkAddress={Paths.SIGN_UP}
        />
      </Stack>
      <AlertSnackbar
        open={isGoogleLoginError}
        onClose={() => setIsGoogleLoginError(false)}
      >
        Ошибка авторизации
      </AlertSnackbar>
      {isGoogleLoginLoading && <PageLoading />}
      <AcceptConditionsDialog
        onAccept={handleAcceptConditions}
        open={isConditionsDialogOpened}
        onClose={() => setIsConditionsDialogOpened(false)}
        termsUrl={config.TermsUrl}
        privacyUrl={config.PrivacyUrl}
      />
    </Stack>
  );
};
