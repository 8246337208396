import "./ListItemForm.scss";
import { Box, MenuItem, Select } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import * as React from "react";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";
import { DEFAULT_UNIT, UNITS } from "../../utils/constants";
import { Alert } from "../Alert/Alert";
import { BaseInput } from "../BaseInput/BaseInput";
import { CategoryAutocomplete } from "../CategoryAutocomplete/CategoryAutocomplete";
import { CurtainPopupHeading } from "../CurtainPopupHeading/CurtainPopupHeading";
import { ImageMultiUploader } from "../ImageMultiUploader/ImageMultiUploader";
import { InputLabel } from "../InputLabel/InputLabel";

const DEFAULT_QUANTITY = 1;
const MAX_QUANTITY = 99999;

export type ListItemFormData = {
  localId: number;
  name: string;
  quantity: number;
  unit: string;
  localCategory: ListItemCategoryInternalModel | null;
  photoUrl: string;
};

export interface ListItemFormProps {
  id: string;
  title: string;
  subtitle?: string;
  onSubmit: (data: ListItemFormData) => Promise<void>;
  onClose: () => void;
  onImageUpload: (image: File) => Promise<string | null>;
  categories: ListItemCategoryInternalModel[];
  initialData?: ListItemInternalModel | null;
}

export const ListItemForm = (props: ListItemFormProps) => {
  const [name, setName] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(DEFAULT_QUANTITY);
  const [unit, setUnit] = useState<string>(DEFAULT_UNIT);
  const [category, setCategory] = useState<ListItemCategoryInternalModel | null>(null);
  const [photoUrl, setPhotoUrl] = useState<string>("");
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [isImageUploadingError, setIsImageUploadingError] = useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);
  const [nameError, setNameError] = useState<string>("");

  useEffect(() => {
    setName(props.initialData?.name ?? "");
  }, [props.initialData?.name]);

  useEffect(() => {
    setQuantity(props.initialData?.quantity ?? DEFAULT_QUANTITY);
  }, [props.initialData?.quantity]);

  useEffect(() => {
    setUnit(props.initialData?.unit ?? DEFAULT_UNIT);
  }, [props.initialData?.unit]);

  useEffect(() => {
    setCategory(props.initialData?.localCategory ?? null);
  }, [props.initialData?.localCategory?.localId]);

  useEffect(() => {
    setPhotoUrl(props.initialData?.photoUrl ?? "");
  }, [props.initialData?.photoUrl]);

  const resetForm = () => {
    setName("");
    setQuantity(DEFAULT_QUANTITY);
    setUnit(DEFAULT_UNIT);
    setCategory(null);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value.slice(0, 200));
    setNameError("");
  };

  const handleQuantityKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = +e.target.value.slice(0, MAX_QUANTITY.toString().length);
    setQuantity(newValue);
  };

  const handleImageUpload = async (file: File) => {
    setIsImageUploading(true);
    setPhotoUrl("");
    const imageUrl = await props.onImageUpload(file);
    setIsImageUploading(false);
    if (!imageUrl) {
      setIsImageUploadingError(true);
    } else {
      setIsImageUploadingError(false);
      setPhotoUrl(imageUrl);
    }
  };

  const handleImageDelete = async () => {
    setPhotoUrl("");
  };

  const handleClose = () => {
    props.onClose();
    resetForm();
  };

  const handleSubmit = async () => {
    if (!props.initialData?.localId) {
      return;
    }
    if (name) {
      setIsConfirmDisabled(true);
      const submitData = {
        localId: props.initialData.localId,
        name,
        quantity: quantity || DEFAULT_QUANTITY,
        unit,
        localCategory: category,
        photoUrl,
      };
      await props.onSubmit(submitData);
      handleClose();
      setIsConfirmDisabled(false);
    } else {
      if (!name) {
        setNameError("Поле название не может быть пустым");
      }
    }
  };

  return (
    <Box className="list-item-form">
      <CurtainPopupHeading
        title={props.title}
        onConfirm={handleSubmit}
        onCancel={handleClose}
        onCancelText="Отменить"
        isConfirmDisabled={isConfirmDisabled}
      />
      <Box className="list-item-form-body">
        <Box className="list-item-form-body-name">
          <InputLabel>Название</InputLabel>
          <BaseInput value={name} onChange={handleNameChange} errorMessage={nameError} />
        </Box>
        <Box className="list-item-form-body-quantity">
          <Box className="list-item-form-body-quantity-number">
            <InputLabel>Количество</InputLabel>
            <BaseInput
              value={(quantity || "").toString()}
              onChange={handleQuantityChange}
              type="number"
              inputMode="numeric"
              onBlur={() => setQuantity(quantity || DEFAULT_QUANTITY)}
              onKeyDown={handleQuantityKeyDown}
            />
          </Box>
          <Box className="list-item-form-body-quantity-unit">
            <InputLabel>Единица измерения</InputLabel>
            <Select value={unit} onChange={(e) => setUnit(e.target.value)}>
              {UNITS.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box>
          <InputLabel>Категория</InputLabel>
          <CategoryAutocomplete
            value={category}
            onChange={setCategory}
            categories={props.categories}
          />
        </Box>
        <Box>
          <InputLabel>Изображение</InputLabel>
          <ImageMultiUploader
            id={props.id}
            onUpload={handleImageUpload}
            onDelete={handleImageDelete}
            imagesUrls={[photoUrl].filter(Boolean)}
            isUploading={isImageUploading}
          />
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={isImageUploadingError}
          onClose={() => setIsImageUploadingError(false)}
          autoHideDuration={5000}
        >
          <Alert type="danger" title="Изображение не добавлено">
            Проверьте подключение к сети Интернет
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};
