import "./TransferItemsPage.scss";
import Stack from "@mui/material/Stack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Arrow } from "../../../assets/Icons/Arrow";
import { TransferItemsDialog } from "../../../components/Dialogs/TransferItemsDialog/TransferItemsDialog";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { ListItemsSelection } from "../../../components/ListItemsSelection/ListItemsSelection";
import { PageActionBar } from "../../../components/PageActionBar/PageActionBar";
import { PageBreadcrumbs } from "../../../components/PageBreadcrumbs/PageBreadcrumbs";
import { ListItemInternalModel } from "../../../services/internalStorage/models/ListItemInternalModel";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  copyListItems,
  deleteListItems,
  fetchListItems,
  fetchLists,
  setSelectedList,
} from "../../../store/lists/listsSlice";
import { selectListsState } from "../../../store/lists/selectors";
import { Paths } from "../../../utils/constants";

export const TransferItemsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { lists, listItemsToTransfer } = useAppSelector(selectListsState);
  const { user } = useAppSelector(selectAuthState);
  const [isTransferItemsDialogOpen, setIsTransferItemsDialogOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ListItemInternalModel[]>([
    ...listItemsToTransfer,
  ]);

  const handleTransferItems = async (listLocalId: number) => {
    await dispatch(setSelectedList(listLocalId));
    await dispatch(fetchLists());
    await dispatch(fetchListItems(listLocalId));
    navigate("/");
    if (selectedItems?.length && selectedItems[0].localListId !== listLocalId) {
      setTimeout(async () => {
        await dispatch(deleteListItems({ listItemsToDelete: selectedItems }));
        await dispatch(copyListItems({ listItems: selectedItems, listLocalId }));
      }, 1000);
    }
  };

  return (
    <Stack className="transfer-items-page">
      <PageActionBar
        onLeftButtonClick={() => navigate("/")}
        leftButtonText={"Отменить"}
        onRightButtonClick={() => setIsTransferItemsDialogOpen(true)}
        rightButtonText={"Перенести"}
        rightButtonIcon={<Arrow />}
      />
      <PageBreadcrumbs
        paths={[
          { name: "Список", href: "/" },
          {
            name: `Перенести товары (${selectedItems.length})`,
            href: Paths.TRANSFER_ITEMS,
          },
        ]}
      />
      {listItemsToTransfer.length ? (
        <ListItemsSelection
          items={listItemsToTransfer}
          selectedItems={selectedItems}
          onChange={setSelectedItems}
        />
      ) : (
        <EmptyContent title="Нет товаров для переноса" />
      )}
      <TransferItemsDialog
        open={isTransferItemsDialogOpen}
        onClose={() => setIsTransferItemsDialogOpen(false)}
        onConfirm={handleTransferItems}
        lists={lists}
        itemsCount={selectedItems.length}
        user={user}
      />
    </Stack>
  );
};
