import "./ImageMultiUploader.scss";
import { Box, Button, CircularProgress, ListItem, Typography } from "@mui/material";
import { MouseEvent, useState } from "react";

import { Delete } from "../../assets/Icons/Delete";
import { Expand } from "../../assets/Icons/Expand";
import { KebabHorizontal } from "../../assets/Icons/KebabHorizontal";
import { UploadOutlined } from "../../assets/Icons/UploadOutlined";
import { AnchorMenu } from "../AnchorMenu/AnchorMenu";
import { PhotoDialog } from "../Dialogs/PhotoDialog/PhotoDialog";
import { ImageInput } from "../ImageInput/ImageInput";

interface ImageMultiUploaderProps {
  id: string;
  onUpload: (file: File) => void;
  onDelete: (url: string) => void;
  imagesUrls: string[];
  isUploading?: boolean;
}

export const ImageMultiUploader = (props: ImageMultiUploaderProps) => {
  const idTag = `image-multi-uploader${props.id ?? ""}`;
  const [anchorMenu, setAnchorMenu] = useState<HTMLElement | null>(null);
  const [expandedImageUrl, setExpandedImageUrl] = useState<string>("");

  const handleExpandImage = (url: string) => {
    setExpandedImageUrl(url);
  };

  const handleMenuItemClick = (
    e: MouseEvent<HTMLButtonElement>,
    callback?: (url: string) => void,
  ) => {
    e.stopPropagation();
    setAnchorMenu(null);
    if (callback && !!props.imagesUrls?.length) {
      callback(props.imagesUrls[props.imagesUrls.length - 1]);
    }
  };

  return (
    <Box className="image-multi-uploader">
      {(!!props.imagesUrls?.length || !!props.isUploading) && (
        <Box className="image-multi-uploader-photos">
          {props.imagesUrls.map((url) => (
            <Box
              key={url}
              src={url}
              className="image-multi-uploader-photos-item"
              component="img"
              alt="image"
              onClick={() => setExpandedImageUrl(url)}
            />
          ))}
          {!!props.isUploading && (
            <Box className="image-multi-uploader-photos-preloader">
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
      <Box className="image-multi-uploader-controls">
        <Button
          className="image-multi-uploader-controls-upload"
          variant="text"
          color="secondary"
          component="label"
          htmlFor={idTag}
        >
          <ImageInput
            id={idTag}
            onChange={props.onUpload}
            maxWidth={800}
            maxHeight={800}
          />
          <UploadOutlined />
          Загрузить фото
        </Button>
        {!!props.imagesUrls?.length && (
          <Button
            className="image-multi-uploader-controls-edit"
            variant="text"
            color="secondary"
            onClick={(e) => setAnchorMenu(e.currentTarget)}
          >
            <KebabHorizontal />
          </Button>
        )}
      </Box>
      <AnchorMenu anchor={anchorMenu} onClose={() => setAnchorMenu(null)}>
        <ListItem>
          <Button
            variant="text"
            endIcon={<Expand />}
            onClick={(e) => handleMenuItemClick(e, handleExpandImage)}
          >
            <Typography>Увеличить</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="text"
            color="error"
            endIcon={<Delete color="#E02D3C" />}
            onClick={(e) => handleMenuItemClick(e, props.onDelete)}
          >
            <Typography>Удалить</Typography>
          </Button>
        </ListItem>
      </AnchorMenu>
      <PhotoDialog
        open={!!expandedImageUrl}
        onClose={() => setExpandedImageUrl("")}
        photoUrl={expandedImageUrl}
      />
    </Box>
  );
};
