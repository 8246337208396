export const Expand = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M13.2 3.7375H9.7625V2.2625H15.7375V8.2375H14.2625V4.8V4.76982L14.2412 4.79116L4.79116 14.2412L4.76982 14.2625H4.8H8.2375V15.7375H2.2625V9.7625H3.7375V13.2V13.2302L3.75884 13.2088L13.2088 3.75884L13.2302 3.7375H13.2Z"
        fill="#08875D"
        stroke="#08875D"
        strokeWidth="0.025"
      />
    </svg>
  );
};
