import "./PhotoDialog.scss";
import { Box, Dialog } from "@mui/material";

import { Close } from "../../../assets/Icons/Close";

export interface PhotoDialogProps {
  open: boolean;
  onClose: () => void;
  photoUrl: string;
}

export const PhotoDialog = (props: PhotoDialogProps) => {
  return (
    <Dialog
      className="photo-dialog"
      open={props.open}
      onClose={props.onClose}
      onClick={props.onClose}
    >
      <Box className="photo-dialog-close" onClick={props.onClose}>
        <Close />
      </Box>
      <Box
        className="photo-dialog-img"
        component="img"
        src={props.photoUrl}
        onClick={(e) => e.stopPropagation()}
      />
    </Dialog>
  );
};
