import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

import "./PageBreadcrumbs.scss";

export interface AppTopBarProps {
  paths: { name: string; href: string }[];
}

export const PageBreadcrumbs = (props: AppTopBarProps) => {
  return (
    <Breadcrumbs className="page-breadcrumbs">
      {props.paths.map((path) => (
        <Link key={path.href} to={path.href}>
          {path.name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};
