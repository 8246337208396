import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import "./CategoryAutocomplete.scss";
import React, { useMemo } from "react";

import { AddIcon } from "../../assets/Icons/AddIcon";
import { SelectChevron } from "../../assets/Icons/SelectChevron";
import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { CategoryBadge } from "../CategoryBadge/CategoryBadge";

export interface CategoryAutocompleteProps {
  categories?: ListItemCategoryInternalModel[];
  value: ListItemCategoryInternalModel | null;
  onChange: (category: ListItemCategoryInternalModel | null) => void;
}

export const CategoryAutocomplete = (props: CategoryAutocompleteProps) => {
  const options: ListItemCategoryInternalModel[] = useMemo(() => {
    if (props.categories) {
      return [...props.categories].sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return [];
    }
  }, [props.categories]);

  const handleChangeAutocomplete = (
    _: React.SyntheticEvent,
    newValue: ListItemCategoryInternalModel | string | null,
  ) => {
    if (typeof newValue === "string") {
      props.onChange(null);
    } else {
      props.onChange(newValue);
    }
  };

  const handleGetOptionLabel = (option: ListItemCategoryInternalModel | string) => {
    if (typeof option === "string") {
      return option;
    }
    return option.name;
  };

  const handleRenderOption = (
    props: React.HTMLAttributes<HTMLElement>,
    option: ListItemCategoryInternalModel,
  ) => {
    return (
      <ListItem key={option.name} {...props} className="category-autocomplete-option">
        <CategoryBadge
          className="category-autocomplete-option-badge"
          color={option.colorDark}
        />
        <Typography className="category-autocomplete-option-name">
          {option.name}
        </Typography>
      </ListItem>
    );
  };

  const handleRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      className="category-autocomplete-input"
      {...params}
      placeholder="Название"
      variant="standard"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <Box className="category-autocomplete-input-badge">
            {props.value?.colorDark ? (
              <CategoryBadge color={props.value.colorDark} />
            ) : (
              <AddIcon />
            )}
          </Box>
        ),
      }}
    />
  );

  return (
    <Autocomplete
      className="category-autocomplete"
      value={props.value}
      options={options}
      onChange={handleChangeAutocomplete}
      getOptionLabel={handleGetOptionLabel}
      renderOption={handleRenderOption}
      renderInput={handleRenderInput}
      clearOnBlur
      handleHomeEndKeys
      ListboxProps={{ style: { maxHeight: "168px", padding: "0" } }}
      popupIcon={<SelectChevron />}
      noOptionsText="Ничего не найдено"
      isOptionEqualToValue={(option, value) => option?.localId !== value?.localId}
    />
  );
};
