import { useSpeechRecognition } from "react-speech-recognition";
import SpeechRecognition from "react-speech-recognition";

export const useWebSpeechRecognition = (): {
  transcript: string;
  resetTranscript: () => void;
  isListening: boolean;
  startListening: (options?: { language?: string }) => Promise<void>;
  stopListening: () => Promise<void>;
  isAvailable: boolean;
} => {
  const { transcript, listening, browserSupportsSpeechRecognition, resetTranscript } =
    useSpeechRecognition();

  const startListening = async (options?: { language?: string }) => {
    if (browserSupportsSpeechRecognition && !listening) {
      await SpeechRecognition.startListening({ language: options?.language || "ru-RU" });
    }
  };

  const stopListening = async () => {
    SpeechRecognition.stopListening();
  };

  return {
    transcript,
    resetTranscript,
    isListening: listening,
    startListening,
    stopListening,
    isAvailable: browserSupportsSpeechRecognition,
  };
};
