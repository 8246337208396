import { Box, IconButton } from "@mui/material";
import { ChangeEvent, MouseEvent } from "react";

import { Minus } from "../../assets/Icons/Minus";
import { Plus } from "../../assets/Icons/Plus";

import "./QuantityInput.scss";

interface QuantityInputProps {
  value: number;
  onChange: (value: number) => void;
  className?: string;
  onEnter?: () => void;
  autofocus?: boolean;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
}

const MIN_VALUE = 0;
const MAX_VALUE = 99999;

export const QuantityInput = (props: QuantityInputProps) => {
  const minValue = props.minValue || MIN_VALUE;
  const maxValue = props.maxValue || MAX_VALUE;

  const clampValue = (value: number): number => {
    if (value < minValue) {
      return minValue;
    } else if (value > maxValue) {
      return maxValue;
    }
    return value;
  };

  const handleMinusClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onChange(clampValue(props.value - 1));
  };

  const handlePlusClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onChange(clampValue(props.value + 1));
  };

  const handleBlur = () => {
    if (props.value === 0) {
      props.onChange(minValue);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newValue = +e.target.value.slice(0, maxValue.toString().length);
    props.onChange(newValue);
  };

  return (
    <Box className={`quantity-input ${props.className ? props.className : ""}`}>
      {!props.disabled && (
        <IconButton onClick={handleMinusClick}>
          <Minus />
        </IconButton>
      )}
      <input
        type="number"
        pattern="[0-9]*"
        inputMode="numeric"
        value={props.value || ""}
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === "Enter" && props.onEnter) {
            props.onEnter();
          }
        }}
        autoFocus={props.autofocus}
        disabled={props.disabled}
        onBlur={handleBlur}
      />
      {!props.disabled && (
        <IconButton onClick={handlePlusClick}>
          <Plus />
        </IconButton>
      )}
    </Box>
  );
};
