import "./ListItemCreationToolbar.scss";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import { Button, Box } from "@mui/material";

import { AiStars } from "../../assets/Icons/AiStars";
import { VoiceAnimation } from "../VoiceAnimation/VoiceAnimation";

export interface ListItemCreationToolbarProps {
  listening: boolean;
  listeningOrigin: "USUAL" | "AI" | null;
  onAddTextListItemClick: () => void;
  onAddVoiceListItemClick: () => void;
  onAddAiListItemClick: () => void;
  onCancelAddVoiceListItemClick: () => void;
  onCancelAddAiListItemClick: () => void;
  isListeningAvailable?: boolean;
  isLlmGeneratingItemsData?: boolean;
}

export const ListItemCreationToolbar = (props: ListItemCreationToolbarProps) => {
  const handleAddVoiceListItemClick = () => {
    if (!props.listening) {
      props.onAddVoiceListItemClick();
    } else if (props.listeningOrigin === "USUAL") {
      props.onCancelAddVoiceListItemClick();
    }
  };

  const handleAddAiListItemClick = () => {
    if (!props.listening) {
      props.onAddAiListItemClick();
    } else if (props.listeningOrigin === "AI") {
      props.onCancelAddAiListItemClick();
    }
  };

  return (
    <Box className="li-creation-toolbar" bottom={"0"}>
      {props.isListeningAvailable && (
        <>
          <Button
            className="li-creation-toolbar-button"
            onClick={handleAddVoiceListItemClick}
            disabled={props.listening && props.listeningOrigin === "AI"}
          >
            {props.listening && props.listeningOrigin === "USUAL" ? (
              <VoiceAnimation className="li-creation-toolbar-button-icon" />
            ) : (
              <>
                <MicNoneOutlinedIcon className="li-creation-toolbar-button-icon" />
                Голосом
              </>
            )}
          </Button>
          <Button
            className={`li-creation-toolbar-ai ${props.isLlmGeneratingItemsData ? "li-creation-toolbar-ai_active" : ""}`}
            onClick={handleAddAiListItemClick}
            disabled={props.listening && props.listeningOrigin === "USUAL"}
          >
            {props.listening && props.listeningOrigin === "AI" ? (
              <VoiceAnimation className="li-creation-toolbar-button-icon" />
            ) : (
              <AiStars />
            )}
          </Button>
        </>
      )}
      <Button
        className="li-creation-toolbar-button"
        onClick={props.onAddTextListItemClick}
      >
        <EditNoteOutlinedIcon
          className="li-creation-toolbar-button-icon"
          fontSize="large"
        />
        Ввод
      </Button>
    </Box>
  );
};
