import * as amplitude from "@amplitude/analytics-browser";
import { Capacitor } from "@capacitor/core";

const MOBILE_DEVICE_REGEX =
  /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

enum USER_PROPS_KEYS {
  APP_PLATFORM = "app_platform",
}

enum PLATFORMS {
  NATIVE_MOBILE_APP = "Native Mobile App",
  TELEGRAM_MOBILE_APP = "Telegram Mobile App",
  TELEGRAM_DESKTOP_APP = "Telegram Desktop App",
  PWA_MOBILE_APP = "PWA Mobile App",
  PWA_DESKTOP_APP = "PWA Desktop App",
  WEB_MOBILE_APP = "Web Mobile App",
  WEB_DESKTOP_APP = "Web Desktop App",
}

class AnalyticsService {
  private _getUserPlatform(): PLATFORMS {
    if (Capacitor.isNativePlatform()) {
      return PLATFORMS.NATIVE_MOBILE_APP;
    }
    const isMobile = MOBILE_DEVICE_REGEX.test(navigator.userAgent);
    if (window.Telegram?.WebApp?.initData) {
      return isMobile ? PLATFORMS.TELEGRAM_MOBILE_APP : PLATFORMS.TELEGRAM_DESKTOP_APP;
    }
    if (window.matchMedia("(display-mode: standalone)").matches) {
      return isMobile ? PLATFORMS.PWA_MOBILE_APP : PLATFORMS.PWA_DESKTOP_APP;
    }
    return isMobile ? PLATFORMS.WEB_MOBILE_APP : PLATFORMS.WEB_DESKTOP_APP;
  }

  private _initUserProps() {
    const identifyEvent = new amplitude.Identify();
    const appPlatform = this._getUserPlatform();
    identifyEvent.set(USER_PROPS_KEYS.APP_PLATFORM, appPlatform);
    amplitude.identify(identifyEvent);
  }

  public init(apiKey: string, userId?: string) {
    if (!apiKey) {
      return;
    }
    amplitude.init(apiKey, userId);
    this._initUserProps();
  }

  public setUserId(userId?: string) {
    amplitude.setUserId(userId);
  }
}

export const analytics = new AnalyticsService();
