import { Box } from "@mui/material";
import { forwardRef, ReactNode } from "react";

import "./Alert.scss";

import { Info } from "../../assets/Icons/Info";

interface AlertProps {
  type?: "success" | "info" | "warning" | "danger";
  title?: ReactNode;
  children?: ReactNode;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>((props: AlertProps, ref) => {
  return (
    <Box className={`alert ${props.type && `alert--${props.type}`}`} ref={ref}>
      <Box className={`alert-icon ${props.type && `alert-icon--${props.type}`}`}>
        <Info />
      </Box>
      <Box className="alert-body">
        <Box className="alert-body-title">{props.title}</Box>
        {props.children}
      </Box>
    </Box>
  );
});

Alert.displayName = "Alert";

export { Alert };
