import "./ResetDialog.scss";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Paths } from "../../../utils/constants";
import { EmptyContent } from "../../EmptyContent/EmptyContent";

export interface ResetDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  signedIn?: boolean;
  isOnline?: boolean;
  isLoading?: boolean;
}

export const ResetDialog = (props: ResetDialogProps) => {
  const navigate = useNavigate();
  return (
    <Dialog open={props.open} onClose={() => props.onClose()} className="reset-dialog">
      <DialogTitle className="reset-dialog-title">
        {props.isOnline
          ? props.signedIn
            ? "Восстановление данных"
            : "Вы не авторизованы"
          : "Нет доступа к сети"}
      </DialogTitle>
      <DialogContent className="reset-dialog-content">
        {props.isLoading ? (
          <EmptyContent image={<CircularProgress />} />
        ) : (
          <Typography>
            {props.isOnline
              ? props.signedIn
                ? "Восстанавливает последние сохраненные данные из облачного хранилища"
                : "Для того чтобы восстановить данные, необходимо войти в приложение"
              : "Для того чтобы восстановить данные, необходимо подключиться к сети"}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="reset-dialog-actions">
        <Button onClick={() => props.onClose()} className="reset-dialog-cancel">
          Отменить
        </Button>
        {props.isOnline && (
          <>
            {props.signedIn ? (
              <Button
                className="reset-dialog-confirm"
                onClick={() => props.onConfirm()}
                variant="contained"
                color="primary"
                disabled={props.isLoading}
              >
                Восстановить
              </Button>
            ) : (
              <Button
                className="reset-dialog-confirm"
                onClick={() => navigate(Paths.LOGIN_HOME)}
                variant="contained"
                color="primary"
              >
                Продолжить
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
