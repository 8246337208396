import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import wishList from "../../../assets/img/wishlist.svg";
import { AlertSnackbar } from "../../../components/AlertSnackbar/AlertSnackbar";
import { BaseButton } from "../../../components/BaseButton/BaseButton";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { FutureFeaturesForm } from "../../../components/FutureFeaturesForm/FutureFeaturesForm";
import { LoginTitle } from "../../../components/LoginTitle/LoginTitle";
import { PageLoading } from "../../../components/PageLoading/PageLoading";
import { sendFutureFeatures } from "../../../store/futureFeatures/futureFeaturesSlice";
import { useAppDispatch } from "../../../store/hooks";

import "./FutureFeaturesPage.scss";

export const FutureFeaturesPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleFutureFeaturesSubmit = async (features: string[]) => {
    setIsSubmitting(true);
    const action = await dispatch(sendFutureFeatures(features));
    setIsSubmitting(false);
    if (action.payload) {
      setIsFormSubmitted(true);
    } else {
      setIsError(true);
    }
  };

  const successImage = <Box component="img" src={wishList} alt="wish list" />;

  return (
    <Stack className="future-features">
      <LoginTitle text="Будущие функции" onBackClick={() => navigate(-1)} />
      {!isFormSubmitted ? (
        <FutureFeaturesForm onSubmit={handleFutureFeaturesSubmit} />
      ) : (
        <EmptyContent
          image={successImage}
          title="Спасибо за ваш выбор!"
          subtitle="Мы постараемся сделать нужные функции в скором времени"
          button={
            <BaseButton onClick={() => navigate("/")}>Вернуться на главную</BaseButton>
          }
          className="future-features-success"
        />
      )}
      <AlertSnackbar open={isError} onClose={() => setIsError(false)}>
        Что-то пошло не так...
      </AlertSnackbar>
      {isSubmitting && <PageLoading />}
    </Stack>
  );
};
