export const Share = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M6.99984 25.6667C6.35817 25.6667 5.80887 25.459 5.35192 25.0436C4.89498 24.6282 4.6665 24.1288 4.6665 23.5455V11.8788C4.6665 11.2955 4.89498 10.7961 5.35192 10.3807C5.80887 9.96532 6.35817 9.75762 6.99984 9.75762H10.4998V11.8788H6.99984V23.5455H20.9998V11.8788H17.4998V9.75762H20.9998C21.6415 9.75762 22.1908 9.96532 22.6478 10.3807C23.1047 10.7961 23.3332 11.2955 23.3332 11.8788V23.5455C23.3332 24.1288 23.1047 24.6282 22.6478 25.0436C22.1908 25.459 21.6415 25.6667 20.9998 25.6667H6.99984ZM12.8332 18.2425V6.39019L10.9665 8.08716L9.33317 6.5758L13.9998 2.33337L18.6665 6.5758L17.0332 8.08716L15.1665 6.39019V18.2425H12.8332Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
