import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AdminService, UserActivityDto, UserCreatedDto } from "../../services/openapi";

export type AdminStateType = {
  usersActivities: Record<string, UserActivityDto[]>;
  usersCreated: Record<string, UserCreatedDto[]>;
};

const initialState: AdminStateType = {
  usersActivities: {},
  usersCreated: {},
};

export const fetchUsersActivities = createAsyncThunk<
  Record<string, UserActivityDto[]> | null,
  { startDate?: string; endDate?: string } | void
>("admin/fetchActiveUsersByDays", async (period) => {
  try {
    return await AdminService.getApiAdminUserActivity(period?.startDate, period?.endDate);
  } catch (error) {
    return null;
  }
});

export const fetchUsersCreated = createAsyncThunk<
  Record<string, UserCreatedDto[]> | null,
  { startDate?: string; endDate?: string } | void
>("admin/fetchUsersCreated", async (period) => {
  try {
    return await AdminService.getApiAdminUserCreated(period?.startDate, period?.endDate);
  } catch (error) {
    return null;
  }
});

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsersActivities.fulfilled, (state, action) => {
      if (action.payload) {
        state.usersActivities = action.payload;
      }
    });
    builder.addCase(fetchUsersCreated.fulfilled, (state, action) => {
      if (action.payload) {
        state.usersCreated = action.payload;
      }
    });
  },
});
