export const TabletList = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M35 26.6667C35 31.3807 35 33.7377 33.5355 35.2022C32.0711 36.6667 29.714 36.6667 25 36.6667H15C10.286 36.6667 7.92893 36.6667 6.46447 35.2022C5 33.7377 5 31.3807 5 26.6667V21.6667M26.6667 6.66956C30.2917 6.68973 32.2549 6.85049 33.5355 8.13113C35 9.59559 35 11.9526 35 16.6667V20M13.3333 6.66956C9.70829 6.68973 7.74511 6.85049 6.46447 8.13113C5.18387 9.41172 5.02309 11.3748 5.0029 14.9996"
        stroke="#25029D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M15 29.1667H25" stroke="#25029D" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M13.3333 5.83331C13.3333 4.4526 14.4526 3.33331 15.8333 3.33331H24.1666C25.5474 3.33331 26.6666 4.4526 26.6666 5.83331V7.49998C26.6666 8.88069 25.5474 9.99998 24.1666 9.99998H15.8333C14.4526 9.99998 13.3333 8.88069 13.3333 7.49998V5.83331Z"
        stroke="#25029D"
        strokeWidth="2"
      />
      <path
        d="M13.3333 23.3333H15M26.6666 23.3333H20"
        stroke="#25029D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M28.3334 17.5H25M20 17.5H11.6667"
        stroke="#25029D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
