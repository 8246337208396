import { Box, IconButton, Typography } from "@mui/material";
import { ReactNode, MouseEvent } from "react";

import "./CustomSelectItem.scss";
import { Check } from "../../assets/Icons/Check";

export interface CustomSelectItemProps {
  name?: ReactNode;
  children?: ReactNode;
  className?: string;
  onCheckClick?: ((e: MouseEvent<HTMLElement>) => void) | null;
}

export const CustomSelectItem = (props: CustomSelectItemProps) => {
  return (
    <Box className={`custom-select-item ${props.className ? props.className : ""}`}>
      <Typography className="custom-select-item-label">{props.name}</Typography>
      <Box className="custom-select-item-body">
        {props.children}
        {props.onCheckClick && (
          <IconButton
            className="custom-select-item-body-confirm"
            onClick={props.onCheckClick}
          >
            <Check />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
