import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import {
  ListInternalModel,
  ListUpdateParams,
} from "../../services/internalStorage/models/ListInternalModel";
import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import {
  ListItemInternalModel,
  ListItemUpdateParams,
} from "../../services/internalStorage/models/ListItemInternalModel";
import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import { InternalStorageCategoriesService } from "../../services/internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageCommonService } from "../../services/internalStorage/services/InternalStorageCommonService";
import { InternalStorageListsService } from "../../services/internalStorage/services/InternalStorageListsService";
import { InternalStoragePromptsService } from "../../services/internalStorage/services/InternalStoragePromptsService";
import {
  FileService,
  ListDetailedDto,
  ListItemShortDto,
  ListItemsService,
  ListsService,
} from "../../services/openapi";
import { convertServerListItemCategoryToLocal } from "../../services/sync/services/SyncListsService";
import { SyncService } from "../../services/sync/services/SyncService";
import {
  COMMON_STORE_KEYS,
  DEFAULT_UNIT,
  LIST_EXAMPLE_NAME,
  LIST_ITEMS_EXAMPLE_NAMES,
  UNITS,
} from "../../utils/constants";
import { getUniqueId } from "../../utils/dateTimeUtil";
import normalizeText, { extractQuantityAndUnit } from "../../utils/normalizeText";
import { addPrompt, updatePrompt } from "../prompts/promptsSlice";

export type ListsStateType = {
  lists: ListInternalModel[];
  listItems: ListItemInternalModel[];
  selectedList: ListInternalModel | null;
  shareListKeyForSharing: string | null;
  shareListKeyForAccept: string | null;
  listToAccept: ListDetailedDto | null;
  areListsLoading: boolean;
  areListItemsLoading: boolean;
  listItemsToTransfer: ListItemInternalModel[];
  listItemsToCopy: ListItemInternalModel[];
  isSmartItemCreationLimitReached: boolean;
  isSmartItemCreationLimitNotificationOpen: boolean;
  isLlmGeneratingItemsData: boolean;
  isListCreatedNotificationOpen: boolean;
};

const initialState: ListsStateType = {
  lists: [],
  listItems: [],
  selectedList: null,
  shareListKeyForSharing: null,
  shareListKeyForAccept: null,
  listToAccept: null,
  areListsLoading: true,
  areListItemsLoading: true,
  listItemsToTransfer: [],
  listItemsToCopy: [],
  isSmartItemCreationLimitReached: false,
  isSmartItemCreationLimitNotificationOpen: false,
  isLlmGeneratingItemsData: false,
  isListCreatedNotificationOpen: false,
};

export const fetchLists = createAsyncThunk<
  { lists: ListInternalModel[]; selectedList: ListInternalModel | null } | null,
  void
>("lists/fetchLists", async () => {
  try {
    const lists = (await InternalStorageListsService.getLists())
      .filter((l) => !l.deleted)
      .sort((a, b) => a.order - b.order);
    let selectedList: ListInternalModel | null;
    const savedSelectedListLocalId = (await InternalStorageCommonService.getValue(
      COMMON_STORE_KEYS.SELECTED_LIST_LOCAL_ID,
    )) as number | undefined;
    const savedSelectedList = lists.find((l) => l.localId === savedSelectedListLocalId);
    if (savedSelectedList) {
      selectedList = { ...savedSelectedList };
    } else if (lists.length) {
      selectedList = lists[0];
    } else {
      selectedList = null;
    }
    return { lists, selectedList };
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const fetchListItems = createAsyncThunk<Array<ListItemInternalModel>, number>(
  "lists/fetchListItems",
  async (listId) => {
    try {
      return await InternalStorageListsService.getListItems(listId);
    } catch (e) {
      console.log(e);
      return [];
    }
  },
);

export const createList = createAsyncThunk<
  ListInternalModel | null,
  { existingLists: ListInternalModel[]; name?: string }
>("lists/createList", async ({ existingLists, name }) => {
  try {
    const currentDateISO = new Date().toISOString();
    const baseName = name ?? dayjs().format("DD.MM.YYYY");
    let uniqueName = baseName;
    let count = 2;
    const existingNamesSet = new Set(existingLists.map((l) => l.name));
    while (existingNamesSet.has(uniqueName)) {
      uniqueName = `${baseName} (${count})`;
      count++;
    }
    const createdList = await InternalStorageListsService.createList({
      name: uniqueName,
      id: null,
      localId: getUniqueId(),
      owner: { name: "", id: "" },
      order: 0,
      created: currentDateISO,
      updated: currentDateISO,
      deleted: null,
    });
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      currentDateISO,
    );
    SyncService.enqueue().catch(console.log);
    return createdList;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const updateList = createAsyncThunk<ListInternalModel | null, ListUpdateParams>(
  "lists/updateList",
  async (list) => {
    try {
      const updatedList = await InternalStorageListsService.updateList(list);
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      SyncService.enqueue().catch(console.log);
      return updatedList;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const removeList = createAsyncThunk<number | null, number>(
  "lists/removeList",
  async (listId) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      const deletedListId = await InternalStorageListsService.deleteList(listId, true);
      SyncService.enqueue().catch(console.log);
      return deletedListId;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const saveSelectedList = createAsyncThunk<void, number>(
  "lists/saveSelectedList",
  async (listLocalId) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.SELECTED_LIST_LOCAL_ID,
        listLocalId,
      );
    } catch (e) {
      console.log(e);
    }
  },
);

export const insertItemsToList = createAsyncThunk<
  {
    itemsToCreate: ListItemInternalModel[];
    itemsToUpdate: ListItemUpdateParams[];
  } | null,
  {
    itemsData: {
      name: string;
      quantity?: number;
      unit?: string;
      category?: ListItemCategoryInternalModel;
      photoUrl?: string;
    }[];
    existingItems: ListItemInternalModel[];
    categories: ListItemCategoryInternalModel[];
    prompts: ListItemPromptInternalModel[];
    listLocalId: number;
  }
>(
  "lists/insertItemsToList",
  async ({ itemsData, existingItems, categories, prompts, listLocalId }) => {
    try {
      const getItemHash = (item: { name: string; unit?: string }) => {
        return normalizeText(`${item.name} ${item.unit ?? DEFAULT_UNIT}`);
      };
      const itemsDataFull = itemsData
        .filter((item) => item.name.trim())
        .map((item) => {
          let prompt = prompts.find(
            (p) => normalizeText(p.name) === normalizeText(item.name),
          );
          if (!prompt) {
            prompt = prompts.find((p) =>
              normalizeText(item.name).split(" ").includes(normalizeText(p.name)),
            );
          }
          const category = categories.find((c) => c.localId === prompt?.localCategoryId);
          return {
            name: item.name,
            quantity: item.quantity || 1,
            unit: item.unit || prompt?.unit || DEFAULT_UNIT,
            category: item.category || category || null,
            photoUrl: item.photoUrl || prompt?.photoUrl || "",
          };
        });
      const uniqueItemsData: Map<
        string,
        {
          name: string;
          quantity: number;
          unit: string;
          category: ListItemCategoryInternalModel | null;
          photoUrl: string;
        }
      > = new Map();
      itemsDataFull.forEach((item) => {
        const hash = getItemHash(item);
        const existing = uniqueItemsData.get(hash);
        uniqueItemsData.set(hash, {
          ...item,
          quantity: (existing?.quantity ?? 0) + (item.quantity ?? 1),
          unit: item?.unit ?? DEFAULT_UNIT,
        });
      });
      const itemsToUpdate: ListItemUpdateParams[] = [];
      const itemsToCreate: ListItemInternalModel[] = [];
      Array.from(uniqueItemsData).forEach(([hash, itemData]) => {
        const existingItem = existingItems
          .filter((listItem) => !listItem.isCompleted)
          .find((listItem) => getItemHash(listItem) === hash);
        if (existingItem) {
          itemsToUpdate.push({
            localId: existingItem.localId,
            quantity: existingItem.quantity + itemData.quantity,
          });
        } else {
          itemsToCreate.push({
            name: itemData.name,
            id: null,
            localId: getUniqueId(),
            localListId: listLocalId,
            isCompleted: false,
            quantity: itemData.quantity,
            unit: itemData.unit,
            photoUrl: itemData.photoUrl,
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            order: 0,
            deleted: null,
            localCategory: itemData.category,
          });
        }
      });
      Promise.all(
        itemsToUpdate.map((item) => InternalStorageListsService.updateListItem(item)),
      ).catch(console.log);
      Promise.all(
        itemsToCreate.map((item) => InternalStorageListsService.createListItem(item)),
      ).catch(console.log);
      InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      ).catch(console.log);
      SyncService.enqueue().catch(console.log);
      return { itemsToCreate, itemsToUpdate };
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const createListItemFromKeyboard = createAsyncThunk<
  void,
  {
    text: string;
    existingItems: ListItemInternalModel[];
    categories: ListItemCategoryInternalModel[];
    prompts: ListItemPromptInternalModel[];
    listLocalId?: number | null;
  }
>(
  "lists/createListItemFromKeyboard",
  async ({ text, existingItems, categories, prompts, listLocalId }, { dispatch }) => {
    try {
      if (!listLocalId || !text.trim()) {
        return;
      }
      const newItem = extractQuantityAndUnit(text, UNITS);
      dispatch(
        insertItemsToList({
          itemsData: [newItem],
          listLocalId,
          categories,
          prompts,
          existingItems,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  },
);

export const createListItemFromVoice = createAsyncThunk<
  void,
  {
    text: string;
    existingItems: ListItemInternalModel[];
    categories: ListItemCategoryInternalModel[];
    prompts: ListItemPromptInternalModel[];
    listLocalId?: number | null;
  }
>(
  "lists/createListItemFromVoice",
  async ({ text, existingItems, categories, prompts, listLocalId }, { dispatch }) => {
    try {
      if (!listLocalId || !text.trim()) {
        return;
      }
      const itemsData = text
        .split(" и ")
        .filter(normalizeText)
        .map((name) => ({ name, quantity: 1, unit: DEFAULT_UNIT }));
      await dispatch(
        insertItemsToList({
          itemsData,
          listLocalId,
          categories,
          prompts,
          existingItems,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  },
);

export const fetchSmartListItemCreationLimit = createAsyncThunk<boolean>(
  "lists/fetchSmartListItemCreationLimit",
  async () => {
    try {
      const isoLastLimitDate = (await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.SMART_ITEM_CREATION_LIMIT_DATE,
      )) as string | undefined;
      if (isoLastLimitDate) {
        const nowUtcDate = new Date().toISOString().split("T")[0];
        const lastLimitUtcDate = isoLastLimitDate?.split("T")[0];
        return lastLimitUtcDate === nowUtcDate;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
);

export const generateLlmItemsData = createAsyncThunk<
  {
    itemsData: ListItemShortDto[] | null;
    isLimitError?: boolean;
  },
  {
    text: string;
    isOnline: boolean;
  }
>("lists/generateLlmItemsData", async ({ text, isOnline }, { dispatch }) => {
  try {
    let itemsData: ListItemShortDto[] | null = null;
    if (isOnline) {
      const isLlmLimitReached = await dispatch(
        fetchSmartListItemCreationLimit(),
      ).unwrap();
      if (!isLlmLimitReached) {
        itemsData = await ListItemsService.postApiListsItemsLlmGenerate({ text });
      }
    }
    return { itemsData };
  } catch (e) {
    if (e instanceof Error && "status" in e && e.status === 409) {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.SMART_ITEM_CREATION_LIMIT_DATE,
        new Date().toISOString(),
      );
      await dispatch(fetchSmartListItemCreationLimit());
      return { itemsData: null, isLimitError: true };
    }
    return { itemsData: null };
  }
});

export const createListItemsFromAi = createAsyncThunk<
  void,
  {
    text: string;
    existingItems: ListItemInternalModel[];
    categories: ListItemCategoryInternalModel[];
    prompts: ListItemPromptInternalModel[];
    isOnline: boolean;
    listLocalId?: number | null;
  }
>(
  "lists/createListItemsFromAi",
  async (
    { text, existingItems, categories, prompts, isOnline, listLocalId },
    { dispatch },
  ) => {
    try {
      if (!listLocalId || !text.trim()) {
        return;
      }
      const llmRes = await dispatch(generateLlmItemsData({ text, isOnline })).unwrap();
      const itemsData = llmRes?.itemsData ?? null;
      if (itemsData) {
        await dispatch(
          insertItemsToList({
            itemsData,
            listLocalId,
            categories,
            prompts,
            existingItems,
          }),
        );
      } else {
        await dispatch(
          createListItemFromVoice({
            text,
            existingItems,
            categories,
            prompts,
            listLocalId,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
);

export const updateListItem = createAsyncThunk<void, ListItemUpdateParams>(
  "lists/updateListItem",
  async (listItemUpdateParams, { dispatch }) => {
    try {
      const item = await InternalStorageListsService.updateListItem(listItemUpdateParams);
      if (
        listItemUpdateParams.localCategory ||
        listItemUpdateParams.photoUrl ||
        listItemUpdateParams.unit
      ) {
        const prompts = await InternalStoragePromptsService.getPrompts();
        const prompt = prompts.find(
          (prompt) => normalizeText(item.name) === normalizeText(prompt.name),
        );
        if (prompt) {
          const promptUpdateParams: Partial<ListItemPromptInternalModel> = {};
          if (listItemUpdateParams.localCategory !== undefined) {
            promptUpdateParams.localCategoryId =
              listItemUpdateParams.localCategory?.localId ?? null;
          }
          if (listItemUpdateParams.photoUrl !== undefined) {
            promptUpdateParams.photoUrl = listItemUpdateParams.photoUrl;
          }
          if (listItemUpdateParams.unit !== undefined) {
            promptUpdateParams.unit = listItemUpdateParams.unit;
          }
          await dispatch(
            updatePrompt({
              ...prompt,
              ...promptUpdateParams,
              updated: new Date().toISOString(),
            }),
          );
        } else {
          await dispatch(
            addPrompt({
              name: normalizeText(item.name),
              localCategoryId: listItemUpdateParams.localCategory?.localId,
              photoUrl: listItemUpdateParams.photoUrl,
              unit: listItemUpdateParams.unit,
            }),
          );
        }
      }
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      SyncService.enqueue().catch(console.log);
      dispatch(fetchListItems(item.localListId));
    } catch (e) {
      console.log(e);
    }
  },
);

export const deleteCategoryFromListItems = createAsyncThunk<void, number>(
  "lists/updateListCategories",
  async (categoryId) => {
    const listItems = await InternalStorageListsService.getAllListItems();
    const updatedListaItems: Promise<ListItemInternalModel>[] = [];
    listItems.forEach((listItem) => {
      if (listItem.localCategory?.localId == categoryId) {
        const updatedListItem = InternalStorageListsService.updateListItem({
          localId: listItem.localId,
          localCategory: null,
        });
        updatedListaItems.push(updatedListItem);
      }
    });
    await Promise.all(updatedListaItems);
  },
);

export const deleteListItems = createAsyncThunk<
  number[] | null,
  { listItemsToDelete: Array<ListItemInternalModel> }
>("lists/deleteListItems", async ({ listItemsToDelete }) => {
  try {
    const deleteRequests = listItemsToDelete.map((listItem) =>
      InternalStorageListsService.deleteListItem(listItem.localId, true),
    );
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      new Date().toISOString(),
    );
    const deletedItemsIds = await Promise.all(deleteRequests);
    SyncService.enqueue().catch(console.log);
    return deletedItemsIds;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const deleteListItemById = createAsyncThunk<number | null, number>(
  "lists/deleteListItemById",
  async (listItemLocalId) => {
    try {
      const deletedItemLocalId = await InternalStorageListsService.deleteListItem(
        listItemLocalId,
        true,
      );
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      SyncService.enqueue().catch(console.log);
      return deletedItemLocalId;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const getShareListKey = createAsyncThunk<string | null, number>(
  "lists/getShareListKey",
  async (listId) => {
    try {
      return await ListsService.getApiListsShareKey(listId);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const getListByShareKey = createAsyncThunk<
  {
    listToAccept: ListDetailedDto | null;
    existingList: ListInternalModel | null;
    shareKey: string | null;
  },
  string
>("lists/getListByShareKey", async (shareKey) => {
  try {
    const listToAccept = await ListsService.getApiListsShareKey1(shareKey);
    const existingList = (await InternalStorageListsService.getLists()).find(
      (list) => list.id === listToAccept.id,
    );
    if (existingList) {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.SELECTED_LIST_LOCAL_ID,
        existingList.localId,
      );
      return { listToAccept: null, existingList, shareKey };
    }
    return { listToAccept, existingList: null, shareKey };
  } catch (e) {
    console.log(e);
    return { listToAccept: null, existingList: null, shareKey: null };
  }
});

export const acceptSharedList = createAsyncThunk<
  ListInternalModel | null,
  {
    listToAccept: ListDetailedDto;
    shareListKeyForAccept: string;
    signedIn: boolean;
  }
>("lists/acceptSharedList", async ({ listToAccept, shareListKeyForAccept, signedIn }) => {
  try {
    if (signedIn) {
      const syncAndCheckSharedListExistence = async () => {
        await SyncService.enqueue();
        const existingList = (await InternalStorageListsService.getLists()).find(
          (list) => list.id === listToAccept.id,
        );
        if (existingList) {
          await InternalStorageCommonService.addOrUpdateValue(
            COMMON_STORE_KEYS.SELECTED_LIST_LOCAL_ID,
            existingList.localId,
          );
          return existingList;
        }
        return null;
      };

      const existingList = await syncAndCheckSharedListExistence();
      if (existingList) {
        return existingList;
      }
      await ListsService.postApiListsShared({ sharedListKey: shareListKeyForAccept });
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      const acceptedList = await syncAndCheckSharedListExistence();
      if (acceptedList) {
        return acceptedList;
      }
      return null;
    }
    const acceptedList = await InternalStorageListsService.createList({
      name: listToAccept.name,
      id: listToAccept.id,
      localId: getUniqueId(),
      owner: listToAccept.owner ?? { name: "", id: "" },
      order: listToAccept.order,
      created: listToAccept.created ?? new Date().toISOString(),
      updated: listToAccept.updated ?? new Date().toISOString(),
      deleted: null,
      shareKey: shareListKeyForAccept,
    });
    if (listToAccept.items?.length) {
      const localCategories = await InternalStorageCategoriesService.getCategories();
      await Promise.all(
        listToAccept.items.map(async (serverListItem) => {
          const localCategory = localCategories.find(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.id === serverListItem.category?.id,
          );
          await InternalStorageListsService.createListItem({
            name: serverListItem.name,
            id: serverListItem.id,
            localId: getUniqueId(),
            localListId: acceptedList.localId,
            isCompleted: serverListItem.isCompleted,
            quantity: serverListItem.quantity ?? 1,
            unit: serverListItem.unit ?? DEFAULT_UNIT,
            photoUrl: serverListItem.photoUrl ?? "",
            created: serverListItem.created || new Date().toISOString(),
            updated: serverListItem.updated || new Date().toISOString(),
            order: Infinity,
            deleted: null,
            localCategory:
              localCategory ??
              convertServerListItemCategoryToLocal(serverListItem.category) ??
              null,
          });
        }),
      );
    }
    return acceptedList;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const deleteAllListsAndItems = createAsyncThunk<void, void>(
  "lists/deleteAllListsAndItems",
  async () => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
        new Date().toISOString(),
      );
      await InternalStorageListsService.deleteAllListsAndItems();
    } catch (e) {
      console.log(e);
    }
  },
);

export const copyListItems = createAsyncThunk<
  void,
  { listItems: ListItemInternalModel[]; listLocalId: number }
>("lists/copyListItems", async ({ listItems, listLocalId }, { dispatch }) => {
  try {
    const currentDateISO = new Date().toISOString();
    await Promise.all(
      listItems.map((listItem) =>
        InternalStorageListsService.createListItem({
          ...listItem,
          id: null,
          localId: getUniqueId(),
          localListId: listLocalId,
          created: currentDateISO,
          updated: currentDateISO,
        }),
      ),
    );
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      new Date().toISOString(),
    );
    await dispatch(fetchLists());
    await dispatch(fetchListItems(listLocalId));
  } catch (e) {
    console.log(e);
  }
});

export const createListExample = createAsyncThunk<
  void,
  { prompts: ListItemPromptInternalModel[]; categories: ListItemCategoryInternalModel[] }
>("lists/createListExample", async ({ prompts, categories }, { dispatch }) => {
  try {
    const createdList = await InternalStorageListsService.createList({
      name: LIST_EXAMPLE_NAME,
      id: null,
      localId: getUniqueId(),
      owner: { name: "", id: "" },
      order: 0,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
      deleted: null,
    });
    await Promise.all(
      LIST_ITEMS_EXAMPLE_NAMES.map(async (name) => {
        const prompt = prompts.find((p) => normalizeText(p.name) === normalizeText(name));
        const category = categories.find((c) => c.localId === prompt?.localCategoryId);
        await InternalStorageListsService.createListItem({
          name,
          id: null,
          localId: getUniqueId(),
          localListId: createdList.localId,
          isCompleted: false,
          quantity: 1,
          unit: DEFAULT_UNIT,
          photoUrl: "",
          created: new Date().toISOString(),
          updated: new Date().toISOString(),
          order: 0,
          deleted: null,
          localCategory: category ?? null,
        });
      }),
    );
    dispatch(fetchLists());
  } catch (e) {
    console.log(e);
  }
});

export const setSelectedList = createAsyncThunk<number | null, number>(
  "lists/selectList",
  async (listLocalId) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.SELECTED_LIST_LOCAL_ID,
        listLocalId,
      );
      return listLocalId;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const uploadListItemImage = createAsyncThunk<string | null, File>(
  "lists/uploadListItemImage",
  async (file) => {
    try {
      return await FileService.postApiFilesUpload({ file });
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    selectList(state, action: { payload: { localId: number } }) {
      const newSelectedList = state.lists.find(
        (l) => l.localId === action.payload.localId,
      );
      if (newSelectedList) {
        state.selectedList = { ...newSelectedList };
      }
    },
    setShareListKeyForAccept(state, action: { payload: string | null }) {
      state.shareListKeyForAccept = action.payload;
    },
    setShareListKeyForSharing(state, action: { payload: string | null }) {
      state.shareListKeyForSharing = action.payload;
    },
    setListToAccept(state, action: { payload: ListDetailedDto | null }) {
      state.listToAccept = action.payload;
    },
    setListItemsToTransfer(state, action: { payload: ListItemInternalModel[] }) {
      state.listItemsToTransfer = action.payload;
    },
    setListItemsToCopy(state, action: { payload: ListItemInternalModel[] }) {
      state.listItemsToCopy = action.payload;
    },
    setIsSmartItemCreationLimitNotificationOpen(state, action: { payload: boolean }) {
      state.isSmartItemCreationLimitNotificationOpen = action.payload;
    },
    setIsListCreatedNotificationOpen(state, action: { payload: boolean }) {
      state.isListCreatedNotificationOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLists.fulfilled, (state, action) => {
      if (action.payload) {
        state.lists = action.payload.lists;
        state.selectedList = action.payload.selectedList;
      } else {
        state.lists = [];
        state.selectedList = null;
        state.listItems = [];
      }
      state.areListsLoading = false;
    });
    builder.addCase(fetchListItems.fulfilled, (state, action) => {
      state.listItems = action.payload.filter((li) => li.deleted === null);
      state.areListItemsLoading = false;
    });
    builder.addCase(createList.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.isListCreatedNotificationOpen = true;
        state.lists.push(action.payload);
        state.selectedList = action.payload;
      }
    });
    builder.addCase(updateList.fulfilled, (state, action) => {
      const updatedList = action.payload;
      if (updatedList !== null) {
        state.lists = state.lists.map((list) => {
          if (list.localId === updatedList.localId) {
            return updatedList;
          } else {
            return list;
          }
        });
        state.selectedList = updatedList;
      }
    });
    builder.addCase(removeList.fulfilled, (state, action) => {
      state.lists = state.lists.filter((list) => list.localId !== action.payload);
      state.selectedList = state.lists.length ? state.lists[0] : null;
      state.listItems = [];
    });
    builder.addCase(insertItemsToList.fulfilled, (state, action) => {
      if (action.payload !== null) {
        const { itemsToCreate, itemsToUpdate } = action.payload;
        state.listItems.push(...itemsToCreate);
        state.listItems = state.listItems.map((listItem) => {
          const updatedItem = itemsToUpdate.find((li) => li.localId === listItem.localId);
          if (updatedItem?.quantity) {
            return {
              ...listItem,
              ...{ quantity: updatedItem.quantity },
            };
          } else {
            return listItem;
          }
        });
      }
    });
    builder.addCase(deleteListItems.fulfilled, (state, action) => {
      if (action.payload !== null) {
        const idsSet = new Set(action.payload);
        state.listItems = state.listItems.filter(
          (listItem) => !idsSet.has(listItem.localId),
        );
      }
    });
    builder.addCase(deleteListItemById.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.listItems = state.listItems.filter(
          (listItem) => listItem.localId !== action.payload,
        );
      }
    });
    builder.addCase(getShareListKey.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.shareListKeyForSharing = action.payload;
      }
    });
    builder.addCase(getListByShareKey.fulfilled, (state, action) => {
      const { existingList, listToAccept, shareKey } = action.payload;
      if (existingList) {
        state.selectedList = existingList;
        state.listToAccept = null;
        state.shareListKeyForAccept = null;
      } else if (listToAccept) {
        state.listToAccept = listToAccept;
        state.shareListKeyForAccept = shareKey;
      } else {
        state.listToAccept = null;
        state.shareListKeyForAccept = null;
      }
    });
    builder.addCase(acceptSharedList.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.lists.push(action.payload);
        state.selectedList = action.payload;
      }
    });
    builder.addCase(deleteAllListsAndItems.fulfilled, (state) => {
      state.lists = [];
      state.listItems = [];
      state.selectedList = null;
    });
    builder.addCase(
      setSelectedList.fulfilled,
      (state, action: { payload: number | null }) => {
        const newSelectedList = state.lists.find((l) => l.localId === action.payload);
        if (newSelectedList) {
          state.selectedList = { ...newSelectedList };
        }
      },
    );
    builder.addCase(
      fetchSmartListItemCreationLimit.fulfilled,
      (state, action: { payload: boolean }) => {
        state.isSmartItemCreationLimitReached = action.payload;
      },
    );
    builder.addCase(generateLlmItemsData.pending, (state) => {
      if (!state.isSmartItemCreationLimitReached) {
        state.isLlmGeneratingItemsData = true;
      }
    });
    builder.addCase(
      generateLlmItemsData.fulfilled,
      (state, action: { payload: { isLimitError?: boolean } }) => {
        state.isLlmGeneratingItemsData = false;
        state.isSmartItemCreationLimitNotificationOpen = !!action.payload.isLimitError;
      },
    );
  },
});

export const {
  selectList,
  setShareListKeyForAccept,
  setShareListKeyForSharing,
  setListToAccept,
  setListItemsToTransfer,
  setListItemsToCopy,
  setIsSmartItemCreationLimitNotificationOpen,
  setIsListCreatedNotificationOpen,
} = listsSlice.actions;
