import "./DigitalKeyboard.scss";
import { Box } from "@mui/material";

import { Backspace } from "../../assets/Icons/Backspace";
import { KeyboardButton } from "../KeyboardButton/KeyboardButton";

interface DigitalKeyboardProps {
  onKeyClick: (string: string) => void;
  onBackspaceClick: () => void;
}

export const DigitalKeyboard = (props: DigitalKeyboardProps) => {
  return (
    <Box className="digital-keyboard">
      {[...Array(9).keys()].map((key) => (
        <KeyboardButton
          key={key}
          onClick={() => props.onKeyClick((key + 1).toString())}
          type="success"
        >
          {key + 1}
        </KeyboardButton>
      ))}
      <KeyboardButton onClick={() => props.onKeyClick(".")} type="info">
        .
      </KeyboardButton>
      <KeyboardButton onClick={() => props.onKeyClick("0")} type="success">
        0
      </KeyboardButton>
      <KeyboardButton onClick={props.onBackspaceClick} type="danger">
        <Backspace />
      </KeyboardButton>
    </Box>
  );
};
