export const PersonSpeaking = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle cx="16.6667" cy="9.99998" r="6.66667" stroke="#25029D" strokeWidth="2" />
      <path
        d="M31.6667 3.33331C31.6667 3.33331 35 5.33331 35 9.99998C35 14.6666 31.6667 16.6666 31.6667 16.6666"
        stroke="#25029D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M28.3333 6.66669C28.3333 6.66669 30 7.66669 30 10C30 12.3334 28.3333 13.3334 28.3333 13.3334"
        stroke="#25029D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.6667 34.3585C20.1512 34.7698 18.456 35 16.6667 35C10.2233 35 5 32.0153 5 28.3334C5 24.6515 10.2233 21.6667 16.6667 21.6667C23.11 21.6667 28.3333 24.6515 28.3333 28.3334C28.3333 28.9089 28.2057 29.4673 27.9658 30"
        stroke="#25029D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
