import React from "react";

import "./QuantityButton.scss";

interface QuantityButtonProps {
  quantity?: number;
  unit?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  active?: boolean;
}

export const QuantityButton = (props: QuantityButtonProps) => {
  return (
    <button
      className={`quantity-button ${props.active ? "quantity-button_active" : ""}`}
      onClick={props.onClick}
    >
      {props.quantity}
      <span className="quantity-button-unit">{props.unit}</span>
    </button>
  );
};
