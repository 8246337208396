import { Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";

import { Alert } from "../Alert/Alert";
import "./AppInstalledSnackbar.scss";

interface AppInstalledSnackbarProps {
  open: boolean;
  onClose: () => void;
}

export const AppInstalledSnackbar = (props: AppInstalledSnackbarProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      open={props.open}
      onClose={() => props.onClose()}
      autoHideDuration={5000}
      className="app-installed-snackbar"
    >
      <Alert type="success">
        Приложение успешно установлено. Рекомендуем разместить его на главном экране
        телефона.
        <Box className="app-installed-snackbar-link" onClick={props.onClose}>
          Ок
        </Box>
      </Alert>
    </Snackbar>
  );
};
