export const Close = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.9998 8.39999L2.0998 13.3C1.91647 13.4833 1.68314 13.575 1.3998 13.575C1.11647 13.575 0.883138 13.4833 0.699805 13.3C0.516471 13.1167 0.424805 12.8833 0.424805 12.6C0.424805 12.3167 0.516471 12.0833 0.699805 11.9L5.5998 6.99999L0.699805 2.09999C0.516471 1.91665 0.424805 1.68332 0.424805 1.39999C0.424805 1.11665 0.516471 0.883321 0.699805 0.699988C0.883138 0.516654 1.11647 0.424988 1.3998 0.424988C1.68314 0.424988 1.91647 0.516654 2.0998 0.699988L6.9998 5.59999L11.8998 0.699988C12.0831 0.516654 12.3165 0.424988 12.5998 0.424988C12.8831 0.424988 13.1165 0.516654 13.2998 0.699988C13.4831 0.883321 13.5748 1.11665 13.5748 1.39999C13.5748 1.68332 13.4831 1.91665 13.2998 2.09999L8.3998 6.99999L13.2998 11.9C13.4831 12.0833 13.5748 12.3167 13.5748 12.6C13.5748 12.8833 13.4831 13.1167 13.2998 13.3C13.1165 13.4833 12.8831 13.575 12.5998 13.575C12.3165 13.575 12.0831 13.4833 11.8998 13.3L6.9998 8.39999Z"
        fill="#08875D"
      />
    </svg>
  );
};
