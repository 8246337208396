import LinkIcon from "@mui/icons-material/Link";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import "./ShareList.scss";
import { SignalLost } from "../../assets/Icons/SignalLost";
import { ListInternalModel } from "../../services/internalStorage/models/ListInternalModel";
import { URL_PARAMS } from "../../utils/constants";
import { CopyField } from "../CopyField/CopyField";
import { EmptyContent } from "../EmptyContent/EmptyContent";

export const getShareText = (shareLink: string) => {
  const shareTextTitle =
    "Привет! Отправляю тебе список покупок из приложения Listy. Посмотри здесь:";
  const shareTextContent =
    "P.S. В Listy делиться списками проще. Установи приложение сейчас:";
  const appLink = "https://listy-app.com";
  return `${shareTextTitle}\n${shareLink}\n\n${shareTextContent}\n${appLink}`;
};

export interface ShareListProps {
  onFetchShareKey: (listId: number | null) => void;
  shareListKey: string | null;
  selectedList: ListInternalModel;
  isOnline: boolean;
  signedIn: boolean;
  telegramMiniAppUrl: string;
  isTelegramMiniApp?: boolean;
}

export const ShareList = (props: ShareListProps) => {
  const [shareLink, setShareLink] = useState("");
  const [telegramShareLink, setTelegramShareLink] = useState("");

  useEffect(() => {
    if (props.isOnline && props.signedIn) {
      props.onFetchShareKey(props.selectedList.id);
    }
  }, [props.selectedList.id, props.isOnline, props.signedIn]);

  useEffect(() => {
    if (props.shareListKey !== null) {
      setShareLink(
        `${window.location.origin?.replace("localhost", "my.listy-app.com")}?${URL_PARAMS.SHARE_LIST_KEY}=${props.shareListKey}`,
      );
    }
  }, [props.shareListKey]);

  useEffect(() => {
    if (props.shareListKey !== null) {
      setTelegramShareLink(`${props.telegramMiniAppUrl}?startapp=${props.shareListKey}`);
    }
  }, [props.shareListKey, props.telegramMiniAppUrl]);

  const handleShareLinkClick = async () => {
    try {
      await navigator.share({
        title: "Listy",
        text: getShareText(shareLink),
      });
    } catch (err) {
      console.log(`Couldn't share ${err}`);
    }
  };

  const handleWhatsAppClick = () => {
    const shareText = encodeURIComponent(getShareText(shareLink));
    window.open(`https://wa.me/?text=${shareText}`, "_blank");
  };

  const handleTelegramClick = () => {
    const shareText = encodeURIComponent(getShareText(telegramShareLink));
    window.open(`https://t.me/share/url?url=%20&text=${shareText}`, "_blank");
  };

  if (!props.isOnline) {
    return (
      <EmptyContent
        image={<SignalLost />}
        title="Нет доступа к интернету"
        subtitle="Поделиться списком можно только при наличии интернета"
      />
    );
  }

  if (!props.signedIn) {
    return (
      <Typography>
        Для того чтобы поделиться списком, необходимо войти в приложение
      </Typography>
    );
  }

  if (!shareLink) {
    return (
      <Box className="share-list-loader-container">
        <CircularProgress className="share-list-loader" />
      </Box>
    );
  }

  return (
    <Box className="share-list">
      <Box className="share-list-copy">
        <Typography>Скопировать ссылку</Typography>
        <CopyField value={shareLink} />
      </Box>
      <List className="share-list-tabs">
        <ListItem className="share-list-tabs-item" onClick={handleWhatsAppClick}>
          <IconButton size="large">
            <WhatsAppIcon fontSize="large" />
          </IconButton>
          <Typography className="share-list-tabs-item-text">WhatsApp</Typography>
        </ListItem>
        <ListItem className="share-list-tabs-item" onClick={handleTelegramClick}>
          <IconButton size="large">
            <TelegramIcon fontSize="large" />
          </IconButton>
          <Typography className="share-list-tabs-item-text">Телеграм</Typography>
        </ListItem>
        {!props.isTelegramMiniApp && (
          <ListItem className="share-list-tabs-item" onClick={handleShareLinkClick}>
            <IconButton size="large">
              <LinkIcon fontSize="large" />
            </IconButton>
            <Typography className="share-list-tabs-item-text">Другое</Typography>
          </ListItem>
        )}
      </List>
    </Box>
  );
};
