import { Capacitor } from "@capacitor/core";

import { useNativeSpeechRecognition } from "./useNativeSpeechRecognition";
import { useWebSpeechRecognition } from "./useWebSpeechRecognition";

interface UseSpeechRecognitionInterface {
  transcript: string;
  resetTranscript: () => void;
  isListening: boolean;
  startListening: (options?: { language?: string }) => Promise<void>;
  stopListening: () => Promise<void>;
  isAvailable: boolean;
  permissionAttempts?: number;
  openSettings?: () => Promise<void>;
}

export const useSpeechRecognition: () => UseSpeechRecognitionInterface =
  Capacitor.isNativePlatform() ? useNativeSpeechRecognition : useWebSpeechRecognition;
