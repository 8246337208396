import "./QuantityKeyboard.scss";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { UNITS } from "../../utils/constants";
import { CurtainPopup } from "../CurtainPopup/CurtainPopup";
import { DigitalKeyboard } from "../DigitalKeyboard/DigitalKeyboard";

interface QuantityKeyboardProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (quantity: number, unit: string) => void;
}

const MAX_QUANTITY_DIGITS = 5;

export const QuantityKeyboard = (props: QuantityKeyboardProps) => {
  const [quantity, setQuantity] = useState<string>("");
  const [unit, setUnit] = useState<string>("");

  useEffect(() => {
    setQuantity("");
    setUnit("");
  }, [props.open]);

  const handleKeyClick = (key: string) => {
    if (key === "." && quantity.includes(".")) {
      return;
    }
    setQuantity((quantity + key).slice(0, MAX_QUANTITY_DIGITS));
  };

  const handleConfirm = () => {
    props.onConfirm(+quantity || 0, unit);
    props.onClose();
  };

  return (
    <CurtainPopup open={props.open} onClose={props.onClose} className="quantity-keyboard">
      <Box className="quantity-keyboard-heading">
        <Button
          className="quantity-keyboard-heading-button"
          onClick={props.onClose}
          color="secondary"
        >
          Закрыть
        </Button>
        <Button className="quantity-keyboard-heading-button" onClick={handleConfirm}>
          Готово
        </Button>
      </Box>
      <Box className="quantity-keyboard-display">
        <Typography className="quantity-keyboard-display-item">
          {quantity || (
            <span className="quantity-keyboard-display-item-disabled">кол-во</span>
          )}
        </Typography>
        <Typography className="quantity-keyboard-display-item">
          {unit || (
            <span className="quantity-keyboard-display-item-disabled">ед.изм.</span>
          )}
        </Typography>
      </Box>
      <Box className="quantity-keyboard-units">
        {UNITS.map((value) => (
          <Button
            key={value}
            className={`quantity-keyboard-units-button ${value === unit ? "quantity-keyboard-units-button_active" : ""}`}
            onClick={() => setUnit(value)}
          >
            {value}
          </Button>
        ))}
      </Box>
      <Box className="quantity-keyboard-digits">
        <DigitalKeyboard
          onKeyClick={handleKeyClick}
          onBackspaceClick={() => setQuantity(quantity.slice(0, quantity.length - 1))}
        />
      </Box>
    </CurtainPopup>
  );
};
