import "./CategorySelection.scss";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";
import { getDateValue } from "../../utils/dateTimeUtil";
import normalizeText from "../../utils/normalizeText";
import { BaseInput } from "../BaseInput/BaseInput";
import { CategoriesList } from "../CategoriesList/CategoriesList";
import { CurtainPopupHeading } from "../CurtainPopupHeading/CurtainPopupHeading";

export interface CategorySelectionProps {
  categories: ListItemCategoryInternalModel[];
  currentListItem?: ListItemInternalModel;
  onSelect: (category: ListItemCategoryInternalModel) => void;
  onClose: () => void;
  reset: boolean;
  onCreateCategoryClick: () => void;
  onEditCategoryClick: (categoryToEdit: ListItemCategoryInternalModel) => void;
  createdCategory: ListItemCategoryInternalModel | null;
}

export const CategorySelection = (props: CategorySelectionProps) => {
  const [searchInput, setSearchInput] = useState("");
  const [categoriesToRender, setCategoriesToRender] = useState<
    ListItemCategoryInternalModel[]
  >([]);

  useEffect(() => {
    if (props.reset) {
      setSearchInput("");
    }
  }, [props.reset]);

  useEffect(() => {
    const searchValue = normalizeText(searchInput);
    const searchedCategories = props.categories
      .filter((c) => normalizeText(c.name).includes(searchValue))
      .sort(
        (a, b) =>
          a.name.indexOf(searchValue) - b.name.indexOf(searchValue) ||
          a.name.localeCompare(b.name) ||
          getDateValue(b.created) - getDateValue(a.created),
      );
    const searchedCreatedCategory = searchedCategories.find(
      (c) => c.localId === props.createdCategory?.localId,
    );
    if (searchedCreatedCategory) {
      setCategoriesToRender([
        searchedCreatedCategory,
        ...searchedCategories.filter(
          (c) => c.localId !== searchedCreatedCategory.localId,
        ),
      ]);
    } else {
      setCategoriesToRender(searchedCategories);
    }
  }, [props.categories, searchInput, props.createdCategory]);

  const handleConfirm = () => {
    const categoryToSelect = props.categories.find(
      (c) => c.localId === props.createdCategory?.localId,
    );
    if (categoryToSelect) {
      props.onSelect(categoryToSelect);
    } else {
      props.onClose();
    }
  };

  return (
    <Box className="category-selection">
      <CurtainPopupHeading
        title="Выбор категории"
        onCancel={props.onClose}
        onConfirm={handleConfirm}
      />
      <BaseInput
        className="category-selection-input"
        placeholder="Поиск по категориям"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value.slice(0, 200))}
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <Button
        className="category-selection-add-button"
        variant="text"
        color="primary"
        startIcon={<AddOutlinedIcon />}
        onClick={props.onCreateCategoryClick}
      >
        Создать категорию
      </Button>
      <CategoriesList
        categories={categoriesToRender}
        selectedCategory={
          props.createdCategory ?? props.currentListItem?.localCategory ?? null
        }
        onCategoryClick={props.onSelect}
        onEditCategoryClick={props.onEditCategoryClick}
        disableAutoscroll={!!props.createdCategory}
      />
    </Box>
  );
};
