import { useEffect, useState } from "react";

import { ListItemCategoryInternalModel } from "../../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../../services/internalStorage/models/ListItemInternalModel";
import { CategoryCreation } from "../../CategoryCreation/CategoryCreation";
import { CategoryEditing } from "../../CategoryEditing/CategoryEditing";
import { CategorySelection } from "../../CategorySelection/CategorySelection";
import { CurtainPopup } from "../../CurtainPopup/CurtainPopup";

export interface ListItemCategoryDialogProps {
  open: boolean;
  onClose: () => void;
  categories: ListItemCategoryInternalModel[];
  onCategoryChange: (newCategory: ListItemCategoryInternalModel | null) => void;
  onCategoryCreate: (
    name: string,
    color: string,
  ) => Promise<ListItemCategoryInternalModel | null>;
  onCategoryEdit: (editedCategory: ListItemCategoryInternalModel) => void;
  listItem?: ListItemInternalModel;
  onCategoryDelete: (category: ListItemCategoryInternalModel) => void;
}

enum Pages {
  CREATION = "CREATION",
  SELECTION = "SELECTION",
  EDITING = "EDITING",
}

export const ListItemCategoryDialog = (props: ListItemCategoryDialogProps) => {
  const [currentPage, setCurrentPage] = useState<Pages>(Pages.SELECTION);
  const [categoryToEdit, setCategoryToEdit] = useState<ListItemCategoryInternalModel>();
  const [createdCategory, setCreatedCategory] =
    useState<ListItemCategoryInternalModel | null>(null);

  const handleCategoryCreate = async (name: string, color: string) => {
    const newCategory = await props.onCategoryCreate(name, color);
    if (newCategory) {
      setCreatedCategory(newCategory);
    }
  };

  const handleCategorySelect = (newCategory: ListItemCategoryInternalModel | null) => {
    props.onCategoryChange(newCategory);
    props.onClose();
  };

  const handleEditCategoryClick = (categoryToEdit: ListItemCategoryInternalModel) => {
    setCategoryToEdit(categoryToEdit);
    setCurrentPage(Pages.EDITING);
  };

  const handleCategoryDelete = (category: ListItemCategoryInternalModel) => {
    props.onCategoryDelete(category);
    setCurrentPage(Pages.SELECTION);
  };

  useEffect(() => {
    if (props.open) {
      setCurrentPage(Pages.SELECTION);
    }
    setCreatedCategory(null);
  }, [props.open]);

  return (
    <CurtainPopup open={props.open} onClose={props.onClose}>
      {currentPage === Pages.SELECTION && (
        <CategorySelection
          categories={props.categories}
          currentListItem={props.listItem}
          onSelect={handleCategorySelect}
          onClose={props.onClose}
          reset={props.open}
          onCreateCategoryClick={() => setCurrentPage(Pages.CREATION)}
          onEditCategoryClick={handleEditCategoryClick}
          createdCategory={createdCategory}
        />
      )}
      {currentPage === Pages.CREATION && (
        <CategoryCreation
          categories={props.categories}
          currentListItem={props.listItem}
          onCategoryCreate={handleCategoryCreate}
          onBack={() => setCurrentPage(Pages.SELECTION)}
        />
      )}
      {currentPage === Pages.EDITING && categoryToEdit && (
        <CategoryEditing
          categories={props.categories}
          categoryToEdit={categoryToEdit}
          onCategoryEdit={props.onCategoryEdit}
          onCategoryDelete={handleCategoryDelete}
          onBack={() => setCurrentPage(Pages.SELECTION)}
        />
      )}
    </CurtainPopup>
  );
};
