import { useEffect, useState } from "react";
import { usePWAInstall } from "react-use-pwa-install";

import config from "../appConfig/config";
import {
  fetchInstallDialogToggle,
  fetchInstallDialogLastShow,
  setInstallDialogLastShow,
} from "../store/application/applicationSlice";
import { selectApplicationState } from "../store/application/selectors";
import { selectAuthState } from "../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../store/hooks";

export const useInstallDialog = () => {
  const dispatch = useAppDispatch();
  const { signedIn } = useAppSelector(selectAuthState);
  const { windowSize } = useAppSelector(selectApplicationState);
  const install = usePWAInstall() as (() => Promise<boolean> | null) | null;
  const [isInstallDialogOpen, setIsInstallDialogOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const installDialogToggle = !!(await dispatch(fetchInstallDialogToggle())).payload;
      const lastShowTimestamp = Number(
        (await dispatch(fetchInstallDialogLastShow())).payload,
      );
      const shouldShow =
        signedIn &&
        !installDialogToggle &&
        Date.now() - lastShowTimestamp > config.ShowInstallButtonInterval &&
        !!install &&
        windowSize.width < 768;
      if (shouldShow) {
        setIsInstallDialogOpen(true);
        dispatch(setInstallDialogLastShow(Date.now()));
      }
    }, config.ShowInstallButtonDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [signedIn, install, dispatch, windowSize.width]);

  return { isInstallDialogOpen, setIsInstallDialogOpen, install };
};
