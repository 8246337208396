import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Button, Box, Typography, IconButton, ListItem } from "@mui/material";
import "./ListItemButton.scss";
import { MouseEvent, TouchEvent, useState } from "react";

import { AddIcon } from "../../assets/Icons/AddIcon";
import { Delete } from "../../assets/Icons/Delete";
import { Edit } from "../../assets/Icons/Edit";
import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import { DEFAULT_UNIT } from "../../utils/constants";
import { AnchorMenu } from "../AnchorMenu/AnchorMenu";
import { CategoryBadge } from "../CategoryBadge/CategoryBadge";
import { ListItemAutocomplete } from "../ListItemAutocomplete/ListItemAutocomplete";
import { QuantityButton } from "../QuantityButton/QuantityButton";
import { QuantityKeyboard } from "../QuantityKeyboard/QuantityKeyboard";

const LONG_PRESS_TIME = 500;
const QUANTITY_KEYBOARD_DELAY_MS = 50;

export interface ListItemButtonProps {
  name?: string;
  localId?: number;
  category?: ListItemCategoryInternalModel | null;
  prompts?: ListItemPromptInternalModel[];
  isChecked: boolean;
  quantity?: number;
  unit?: string;
  onQuantityChange?: (localId: number, quantity: number, unit: string) => void;
  onClick?: (listItemId: number) => void;
  onChangeCategoryClick?: (listItemId: number) => void;
  onEditClick?: (listItemId: number) => void;
  onDeleteClick?: (listItemId: number) => void;
  onSaveNewListItem?: (listItemName: string, quantity: number) => void;
  onCancelListItemCreation?: () => void;
  isListItemCreationOpened?: boolean;
  onInputBlur?: () => void;
}

export const ListItemButton = (props: ListItemButtonProps) => {
  const [isQuantityKeyboardOpen, setIsQuantityKeyboardOpen] = useState(false);
  const [pressTimer, setPressTimer] = useState<NodeJS.Timeout | null>(null);
  const [isLongPress, setIsLongPress] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState<HTMLElement | null>(null);

  const handleTouchStart = (
    e: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>,
  ) => {
    setIsLongPress(false);
    const target = e.currentTarget;
    const timer = setTimeout(() => {
      if (props.onEditClick && props.onDeleteClick) {
        setAnchorMenu(target);
        setIsLongPress(true);
        try {
          navigator.vibrate(100);
        } catch (e) {
          console.log(e);
        }
      }
    }, LONG_PRESS_TIME);
    setPressTimer(timer);
  };

  const handleTouchEnd = () => {
    if (pressTimer) {
      clearTimeout(pressTimer);
      setPressTimer(null);
    }
  };

  const handleClick = () => {
    if (props.onClick && props.localId && !isLongPress) {
      props.onClick(props.localId);
    }
  };

  const handleEditCategoryClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (props.onChangeCategoryClick && props.localId) {
      props.onChangeCategoryClick(props.localId);
    }
  };

  const handleEditQuantityClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (props.isListItemCreationOpened) {
      setTimeout(() => {
        setIsQuantityKeyboardOpen(true);
      }, QUANTITY_KEYBOARD_DELAY_MS);
    } else {
      setIsQuantityKeyboardOpen(true);
    }
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      if (props.onInputBlur) {
        props.onInputBlur();
      }
    }, QUANTITY_KEYBOARD_DELAY_MS);
  };

  const handleQuantityKeyboardConfirm = (quantity: number, unit: string) => {
    setIsQuantityKeyboardOpen(false);
    if (!quantity && !unit) {
      return;
    }
    if (props.onQuantityChange && props.localId) {
      props.onQuantityChange(
        props.localId,
        quantity || props.quantity || 1,
        unit || props.unit || DEFAULT_UNIT,
      );
    }
  };

  const handleMenuItemClick = (
    e: MouseEvent<HTMLButtonElement>,
    callback?: (id: number) => void,
  ) => {
    e.stopPropagation();
    setAnchorMenu(null);
    if (props.localId && callback) {
      callback(props.localId);
    }
  };

  return (
    <Box className="list-item-button">
      <Button
        color="secondary"
        className="list-item-button-body"
        onClick={handleClick}
        onMouseDown={handleTouchStart}
        onMouseUp={handleTouchEnd}
        onMouseLeave={handleTouchEnd}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {props.isChecked ? (
          <CheckCircleRoundedIcon
            color="primary"
            className="list-item-button-body-checked-icon"
          />
        ) : (
          <Box className="list-item-button-body-unchecked-icon" />
        )}
        {props.onSaveNewListItem ? (
          <ListItemAutocomplete
            prompts={props.prompts}
            onChange={props.onSaveNewListItem}
            onBlur={handleInputBlur}
          />
        ) : (
          <Typography className="list-item-button-body-name">{props.name}</Typography>
        )}
      </Button>
      <Box className="list-item-button-controls">
        {props.quantity && (
          <QuantityButton
            quantity={props.quantity}
            unit={props.unit ?? DEFAULT_UNIT}
            onClick={handleEditQuantityClick}
            active={isQuantityKeyboardOpen}
          />
        )}
        {props.onChangeCategoryClick && (
          <IconButton
            className="list-item-button-controls-button"
            onClick={handleEditCategoryClick}
          >
            {props.category ? (
              <CategoryBadge color={props.category.colorDark} />
            ) : (
              <AddIcon />
            )}
          </IconButton>
        )}
        {props.onCancelListItemCreation && (
          <IconButton
            className="list-item-button-controls-button"
            onClick={props.onCancelListItemCreation}
          >
            <CancelOutlinedIcon color="error" />
          </IconButton>
        )}
      </Box>
      <QuantityKeyboard
        open={isQuantityKeyboardOpen}
        onClose={() => setIsQuantityKeyboardOpen(false)}
        onConfirm={handleQuantityKeyboardConfirm}
      />
      <AnchorMenu anchor={anchorMenu} onClose={() => setAnchorMenu(null)}>
        <ListItem>
          <Button
            variant="text"
            endIcon={<Edit />}
            onClick={(e) => handleMenuItemClick(e, props.onEditClick)}
          >
            <Typography>Редактировать</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="text"
            color="error"
            endIcon={<Delete color="#E02D3C" />}
            onClick={(e) => handleMenuItemClick(e, props.onDeleteClick)}
          >
            <Typography>Удалить</Typography>
          </Button>
        </ListItem>
      </AnchorMenu>
    </Box>
  );
};
