import { useEffect, useRef, useState } from "react";

import { selectApplicationState } from "../store/application/selectors";
import { loginToTelegramMiniApp } from "../store/auth/authSlice";
import { selectAuthState } from "../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../store/hooks";

export const useTelegramAppLogin = () => {
  const dispatch = useAppDispatch();
  const { telegramInitData } = useAppSelector(selectApplicationState);
  const { signedIn } = useAppSelector(selectAuthState);
  const [isLoading, setIsLoading] = useState(false);
  const loginRef = useRef<boolean>(false);

  useEffect(() => {
    if (!loginRef.current && telegramInitData && !signedIn) {
      loginRef.current = true;
      setIsLoading(true);
      dispatch(loginToTelegramMiniApp(telegramInitData)).finally(() =>
        setIsLoading(false),
      );
    }
  }, []);

  return { isLoading };
};
