import { useEffect, useState } from "react";

export const useAppInstalled = () => {
  const [appInstalled, setAppInstalled] = useState(false);

  useEffect(() => {
    const handleAppInstall = () => {
      setAppInstalled(true);
    };
    window.addEventListener("appinstalled", handleAppInstall);
    return () => window.removeEventListener("appinstalled", handleAppInstall);
  }, []);

  return { appInstalled };
};
