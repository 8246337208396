import { Box, TextField } from "@mui/material";
import React, { KeyboardEvent, ReactNode } from "react";

import "./BaseInput.scss";
import { InputError } from "../InputError/InputError";

interface BaseInputProps {
  className?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  disabled?: boolean;
  errorMessage?: string;
  onEnter?: () => void;
  autoFocus?: boolean;
  type?: string;
  inputMode?:
    | "search"
    | "text"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal";
  onBlur?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
}

export const BaseInput = (props: BaseInputProps) => {
  return (
    <Box className={`base-input ${props.className ? props.className : ""}`}>
      <TextField
        variant="outlined"
        placeholder={props.placeholder}
        className="base-input-field"
        inputProps={{
          type: props.type,
          inputMode: props.inputMode,
        }}
        InputProps={{
          autoComplete: "off",
          startAdornment: props.startAdornment,
          endAdornment: props.endAdornment,
        }}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        error={!!props.errorMessage}
        onKeyDown={(e) => {
          if (props.onKeyDown) {
            props.onKeyDown(e);
          }
          if (e.key === "Enter" && props.onEnter) {
            props.onEnter();
          }
        }}
        autoFocus={props.autoFocus}
        onBlur={props.onBlur}
      />
      {props.errorMessage && (
        <InputError className="base-input-error" type="danger">
          {props.errorMessage}
        </InputError>
      )}
    </Box>
  );
};
