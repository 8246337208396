import { AppBar, CircularProgress } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import config from "../../../appConfig/config";
import { ListIcon } from "../../../assets/Icons/ListIcon";
import { AiVoiceIntro } from "../../../components/AiVoiceIntro/AiVoiceIntro";
import { Alert } from "../../../components/Alert/Alert";
import { AppMenu } from "../../../components/AppMenu/AppMenu";
import { AppTopBar } from "../../../components/AppTopBar/AppTopBar";
import { BaseButton } from "../../../components/BaseButton/BaseButton";
import { AcceptListDialog } from "../../../components/Dialogs/AcceptListDialog/AcceptListDialog";
import { CreateListDialog } from "../../../components/Dialogs/CreateListDialog/CreateListDialog";
import { DeleteDialog } from "../../../components/Dialogs/DeleteDialog/DeleteDialog";
import { EditListDialog } from "../../../components/Dialogs/EditListDialog/EditListDialog";
import { EditListItemDialog } from "../../../components/Dialogs/EditListItemDialog/EditListItemDialog";
import { ListItemCategoryDialog } from "../../../components/Dialogs/ListItemCategoryDialog/ListItemCategoryDialog";
import { ShareListDialog } from "../../../components/Dialogs/ShareListDialog/ShareListDialog";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { ListItemCreationToolbar } from "../../../components/ListItemCreationToolbar/ListItemCreationToolbar";
import { ListItems } from "../../../components/ListItems/ListItems";
import { ListsToolbar } from "../../../components/ListsToolbar/ListsToolbar";
import { MicAccessSnackbar } from "../../../components/MicAccessSnackbar/MicAccessSnackbar";
import { useSpeechRecognition } from "../../../hooks/useSpeechRecognition";
import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { ListItemCategoryInternalModel } from "../../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemUpdateParams } from "../../../services/internalStorage/models/ListItemInternalModel";
import {
  enqueueSync,
  setFirstVisitDate,
  setHasTriedAiVoice,
} from "../../../store/application/applicationSlice";
import { selectApplicationState } from "../../../store/application/selectors";
import { logoutCleanup } from "../../../store/auth/authSlice";
import { selectAuthState } from "../../../store/auth/selectors";
import {
  addCategory,
  deleteOneCategory,
  fetchCategories,
  updateCategory,
} from "../../../store/categories/categoriesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  deleteListItems,
  fetchListItems,
  updateListItem,
  getListByShareKey,
  acceptSharedList,
  createList,
  removeList,
  selectList,
  getShareListKey,
  fetchLists,
  setShareListKeyForAccept,
  saveSelectedList,
  deleteCategoryFromListItems,
  setListToAccept,
  setShareListKeyForSharing,
  createListExample,
  updateList,
  setListItemsToTransfer,
  setListItemsToCopy,
  uploadListItemImage,
  createListItemFromKeyboard,
  createListItemFromVoice,
  fetchSmartListItemCreationLimit,
  setIsSmartItemCreationLimitNotificationOpen,
  setIsListCreatedNotificationOpen,
  createListItemsFromAi,
} from "../../../store/lists/listsSlice";
import {
  selectCompletedListItems,
  selectGroupedByCategoryListItems,
  selectListsState,
} from "../../../store/lists/selectors";
import { fetchPrompts } from "../../../store/prompts/promptsSlice";
import { Paths, URL_PARAMS } from "../../../utils/constants";
import normalizeText from "../../../utils/normalizeText";

export const ListsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const speechRecognition = useSpeechRecognition();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    lists,
    listItems,
    selectedList,
    listToAccept,
    shareListKeyForSharing,
    shareListKeyForAccept,
    areListItemsLoading,
    areListsLoading,
    isSmartItemCreationLimitNotificationOpen,
    isLlmGeneratingItemsData,
    isListCreatedNotificationOpen,
  } = useAppSelector(selectListsState);
  const groupedByCategoryListItems = useAppSelector(selectGroupedByCategoryListItems);
  const completedListItems = useAppSelector(selectCompletedListItems);
  const { user, signedIn } = useAppSelector(selectAuthState);
  const {
    isOnline,
    firstVisitDate,
    autoListCreationToggle,
    telegramInitData,
    telegramInitDataUnsafe,
    hasTriedAiVoice,
  } = useAppSelector(selectApplicationState);
  const { prompts } = useAppSelector((state) => state.prompts);
  const { categories } = useAppSelector((state) => state.categories);
  const [isAddListDialogOpen, setIsAddListDialogOpen] = useState(false);
  const [isDeleteListDialogOpen, setIsDeleteListDialogOpen] = useState(false);
  const [isShareListDialogOpened, setIsShareListDialogOpened] = useState(false);
  const [isAppMenuOpened, setIsAppMenuOpened] = useState(
    !!searchParams.get(URL_PARAMS.OPEN_SIDEBAR),
  );
  const [isTextListItemCreationOpened, setIsTextListItemCreationOpened] = useState(false);
  const [isEditListItemDialogOpened, setIsEditListItemDialogOpened] = useState(false);
  const [isChangeLiCategoryDialogOpen, setIsChangeLiCategoryDialogOpen] = useState(false);
  const [liIdForCategoryChange, setLiIdForCategoryChange] = useState<number | null>(null);
  const [liIdForEditing, setLiIdForEditing] = useState<number | null>(null);
  const [isListExampleCreating, setIsListExampleCreating] = useState(false);
  const [isEditListDialogOpen, setIsEditListDialogOpen] = useState(false);
  const [isMicAccessSnackbarOpen, setIsMicAccessSnackbarOpen] = useState(false);
  const [listeningOrigin, setListeningOrigin] = useState<"USUAL" | "AI" | null>(null);
  const [isAiVoiceIntroOpen, setIsAiVoiceIntroOpen] = useState(false);

  const handleCreateListClick = async () => {
    if (autoListCreationToggle) {
      dispatch(createList({ existingLists: lists }));
    } else {
      setIsAddListDialogOpen(true);
    }
  };

  const handleCreateList = (name: string) => {
    setIsAddListDialogOpen(false);
    dispatch(createList({ existingLists: lists, name }));
  };

  const handleEditList = (editedList: ListInternalModel) => {
    dispatch(updateList({ localId: editedList.localId, name: editedList.name }));
  };

  const handleDeleteList = () => {
    setIsDeleteListDialogOpen(false);
    if (selectedList) {
      dispatch(removeList(selectedList.localId));
    }
  };

  const handleSelectList = (listId: number) => {
    dispatch(selectList({ localId: listId }));
  };

  const handleFetchShareListKey = (listId: number | null) => {
    if (listId === null) {
      dispatch(enqueueSync());
    } else {
      dispatch(getShareListKey(listId));
    }
  };

  const handleAddTextListItemClick = () => {
    setIsTextListItemCreationOpened(!isTextListItemCreationOpened);
  };

  const handleCancelListItemTextCreation = () => {
    setIsTextListItemCreationOpened(false);
  };

  const handleAddVoiceListItemClick = async () => {
    await speechRecognition.startListening();
    setListeningOrigin("USUAL");
  };

  const handleAddAiListItemClick = async () => {
    if (hasTriedAiVoice) {
      await speechRecognition.startListening();
      setListeningOrigin("AI");
    } else {
      setIsAiVoiceIntroOpen(true);
    }
  };

  const handleConfirmAiVoiceIntro = async () => {
    dispatch(setHasTriedAiVoice(true));
    await speechRecognition.startListening();
    setListeningOrigin("AI");
  };

  const handleListItemVoiceCreation = (input: string) => {
    dispatch(
      createListItemFromVoice({
        text: input,
        listLocalId: selectedList?.localId,
        categories,
        prompts,
        existingItems: listItems,
      }),
    );
  };

  const handleListItemsAiCreation = (input: string) => {
    dispatch(
      createListItemsFromAi({
        text: input,
        listLocalId: selectedList?.localId,
        categories,
        prompts,
        isOnline,
        existingItems: listItems,
      }),
    );
  };

  const handleListItemTextCreation = (name: string) => {
    dispatch(
      createListItemFromKeyboard({
        text: name,
        listLocalId: selectedList?.localId,
        categories,
        prompts,
        existingItems: listItems,
      }),
    );
  };

  const handleCompleteListItem = (localId: number) => {
    if (selectedList) {
      dispatch(updateListItem({ localId, isCompleted: true }));
    }
  };

  const handleUnCompleteListItem = (localId: number) => {
    if (selectedList) {
      dispatch(updateListItem({ localId, isCompleted: false }));
    }
  };

  const handleDeleteAllListItems = () => {
    if (selectedList) {
      dispatch(
        deleteListItems({
          listItemsToDelete: completedListItems,
        }),
      );
    }
  };

  const handleLogout = () => {
    dispatch(logoutCleanup()).then(() => {
      window.location.reload();
    });
  };

  const handleOpenEditListItemDialog = (listItemLocalId: number) => {
    setIsEditListItemDialogOpened(true);
    setLiIdForEditing(listItemLocalId);
  };

  const handleCloseEditListItemDialog = () => {
    setIsEditListItemDialogOpened(false);
    setLiIdForEditing(null);
  };

  const handleUploadListItemImage = async (image: File): Promise<string | null> => {
    const imageUploadAction = await dispatch(uploadListItemImage(image));
    return imageUploadAction.payload as string | null;
  };

  const handleListItemEdit = async (data: ListItemUpdateParams) => {
    await dispatch(updateListItem(data));
  };

  const handleOpenChangeListItemCategoryDialog = (listItemLocalId: number) => {
    setLiIdForCategoryChange(listItemLocalId);
    setIsChangeLiCategoryDialogOpen(true);
  };

  const handleCloseChangeListItemCategoryDialog = () => {
    setLiIdForCategoryChange(null);
    setIsChangeLiCategoryDialogOpen(false);
  };

  const handleListItemCategoryChange = (
    category: ListItemCategoryInternalModel | null,
  ) => {
    if (liIdForCategoryChange) {
      dispatch(
        updateListItem({
          localId: liIdForCategoryChange,
          localCategory: category,
        }),
      );
    }
  };

  const handleCreateCategory = async (name: string, color: string) => {
    const action = await dispatch(addCategory({ name, color }));
    if (action?.payload) {
      return action.payload as ListItemCategoryInternalModel;
    } else {
      return null;
    }
  };

  const handleEditCategory = async (editedCategory: ListItemCategoryInternalModel) => {
    await dispatch(updateCategory(editedCategory));
    if (selectedList) {
      dispatch(fetchListItems(selectedList.localId));
    }
  };
  const handleDeleteCategory = async (category: ListItemCategoryInternalModel) => {
    await dispatch(deleteOneCategory(category));
    await dispatch(deleteCategoryFromListItems(category.localId));
    if (selectedList) {
      dispatch(fetchListItems(selectedList.localId));
    }
  };

  const handleAcceptList = async () => {
    if (shareListKeyForAccept && listToAccept) {
      await dispatch(
        acceptSharedList({
          listToAccept: { ...listToAccept },
          shareListKeyForAccept,
          signedIn,
        }),
      );
    }
    dispatch(setShareListKeyForAccept(null));
    dispatch(setListToAccept(null));
  };

  const handleRejectList = () => {
    dispatch(setShareListKeyForAccept(null));
    dispatch(setListToAccept(null));
  };

  const handleTransferItemsClick = () => {
    dispatch(setListItemsToTransfer([...groupedByCategoryListItems.flat()]));
    navigate(Paths.TRANSFER_ITEMS);
  };

  const handleCopyItemsClick = () => {
    dispatch(setListItemsToCopy([...groupedByCategoryListItems.flat()]));
    navigate(Paths.COPY_ITEMS);
  };

  const handleOpenMenu = () => {
    setIsAppMenuOpened(true);
    searchParams.set(URL_PARAMS.OPEN_SIDEBAR, "true");
    setSearchParams(searchParams);
  };

  const handleCloseMenu = () => {
    setIsAppMenuOpened(false);
    searchParams.delete(URL_PARAMS.OPEN_SIDEBAR);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const shareListKeyForAccept =
      searchParams.get(URL_PARAMS.SHARE_LIST_KEY) || telegramInitDataUnsafe.start_param;
    if (shareListKeyForAccept) {
      dispatch(getListByShareKey(shareListKeyForAccept));
      searchParams.delete(URL_PARAMS.SHARE_LIST_KEY);
      setSearchParams(searchParams);
    }
    const openSidebar = searchParams.get(URL_PARAMS.OPEN_SIDEBAR);
    if (openSidebar) {
      setIsAppMenuOpened(true);
    }
    dispatch(fetchSmartListItemCreationLimit());
  }, []);

  useEffect(() => {
    if (selectedList?.localId) {
      dispatch(fetchListItems(selectedList.localId));
      dispatch(saveSelectedList(selectedList.localId));
      dispatch(setShareListKeyForSharing(null));
    }
  }, [selectedList]);

  useEffect(() => {
    const { isListening, transcript, resetTranscript } = speechRecognition;
    if (selectedList && !isListening && transcript?.length) {
      if (listeningOrigin === "USUAL") {
        handleListItemVoiceCreation(transcript);
      } else if (listeningOrigin === "AI") {
        handleListItemsAiCreation(transcript);
      }
      resetTranscript();
    }
  }, [speechRecognition.isListening]);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchLists());
    dispatch(fetchPrompts());
    dispatch(enqueueSync());
  }, [user]);

  useEffect(() => {
    if (
      !!speechRecognition.permissionAttempts &&
      speechRecognition.permissionAttempts > 2
    ) {
      setIsMicAccessSnackbarOpen(true);
    } else {
      setIsMicAccessSnackbarOpen(false);
    }
  }, [speechRecognition.permissionAttempts]);

  // useEffect(() => {
  //   if (firstVisitDate || signedIn) {
  //     return;
  //   }
  //   setIsListExampleCreating(true);
  //   if (prompts.length && categories.length) {
  //     dispatch(createListExample({ prompts, categories })).finally(() => {
  //       setIsListExampleCreating(false);
  //       dispatch(setFirstVisitDate(new Date().toISOString()));
  //     });
  //   }
  // }, [prompts.length, categories.length, signedIn, firstVisitDate]);

  return (
    <Stack height={"100dvh"} overflow={"auto"}>
      <AppBar position="static">
        <AppTopBar isOnline={isOnline} onMenuClick={handleOpenMenu}>
          <ListsToolbar
            lists={lists}
            selectedList={selectedList}
            user={user}
            onAddListClick={handleCreateListClick}
            onDeleteListClick={() => setIsDeleteListDialogOpen(true)}
            onTransferItemsClick={handleTransferItemsClick}
            onCopyItemsClick={handleCopyItemsClick}
            onShareListClick={() => setIsShareListDialogOpened(true)}
            onEditListClick={() => setIsEditListDialogOpen(true)}
            onSelectList={handleSelectList}
          />
        </AppTopBar>
        <AppMenu
          open={isAppMenuOpened}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          user={user}
          onSignOutClick={handleLogout}
          onAddListClick={handleCreateListClick}
          hideSignOut={!user.id || !!telegramInitData}
        />
      </AppBar>
      <Stack component="main" overflow="auto" flexGrow="1">
        {selectedList && !areListItemsLoading && !isListExampleCreating && (
          <Stack flexGrow="1" alignItems="center" overflow="auto">
            <ListItems
              groupedByCategoryListItems={groupedByCategoryListItems}
              completedListItems={completedListItems}
              prompts={prompts}
              categories={categories}
              isListItemCreationOpened={isTextListItemCreationOpened}
              onListItemComplete={handleCompleteListItem}
              onListItemUnComplete={handleUnCompleteListItem}
              onDeleteAllListItems={handleDeleteAllListItems}
              onChangeCategoryClick={handleOpenChangeListItemCategoryDialog}
              onEditListItemClick={handleOpenEditListItemDialog}
              onSaveNewListItem={handleListItemTextCreation}
              onCancelListItemCreation={handleCancelListItemTextCreation}
            />
            <ListItemCreationToolbar
              listening={speechRecognition.isListening}
              listeningOrigin={listeningOrigin}
              onAddTextListItemClick={handleAddTextListItemClick}
              onAddVoiceListItemClick={handleAddVoiceListItemClick}
              onAddAiListItemClick={handleAddAiListItemClick}
              onCancelAddVoiceListItemClick={() => speechRecognition.stopListening()}
              onCancelAddAiListItemClick={() => speechRecognition.stopListening()}
              isListeningAvailable={speechRecognition.isAvailable}
              isLlmGeneratingItemsData={isLlmGeneratingItemsData}
            />
          </Stack>
        )}
        {!selectedList && !areListsLoading && !isListExampleCreating && (
          <EmptyContent
            image={<ListIcon />}
            title="Добавьте список"
            subtitle="Создайте новый список чтобы иметь возможность добавлять продукты"
            button={
              <BaseButton onClick={handleCreateListClick}>Создать список</BaseButton>
            }
          />
        )}
        {isListExampleCreating && <EmptyContent image={<CircularProgress />} />}
      </Stack>
      <CreateListDialog
        open={isAddListDialogOpen}
        onClose={() => setIsAddListDialogOpen(false)}
        onAdd={handleCreateList}
      />
      <AcceptListDialog
        open={!!listToAccept}
        onAccept={handleAcceptList}
        onReject={handleRejectList}
        list={listToAccept}
      />
      <ListItemCategoryDialog
        open={isChangeLiCategoryDialogOpen}
        onClose={handleCloseChangeListItemCategoryDialog}
        onCategoryChange={handleListItemCategoryChange}
        onCategoryCreate={handleCreateCategory}
        onCategoryEdit={handleEditCategory}
        onCategoryDelete={handleDeleteCategory}
        categories={categories}
        listItem={listItems.find((li) => li.localId === liIdForCategoryChange)}
      />
      <EditListItemDialog
        open={isEditListItemDialogOpened}
        onClose={handleCloseEditListItemDialog}
        onFormSubmit={handleListItemEdit}
        onImageUpload={handleUploadListItemImage}
        categories={categories}
        listItem={listItems.find((li) => li.localId === liIdForEditing)}
      />
      <MicAccessSnackbar
        open={isMicAccessSnackbarOpen}
        onClose={() => setIsMicAccessSnackbarOpen(false)}
        onLinkClick={speechRecognition.openSettings}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        open={isSmartItemCreationLimitNotificationOpen}
        onClose={() => dispatch(setIsSmartItemCreationLimitNotificationOpen(false))}
        autoHideDuration={10000}
      >
        <Alert type="warning">
          Ваш лимит на умный ввод сегодня исчерпан. Теперь будет работать обычный
          голосовой ввод. Скоро мы увеличим лимиты в подписке ПРО.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        open={isListCreatedNotificationOpen}
        onClose={() => dispatch(setIsListCreatedNotificationOpen(false))}
        autoHideDuration={3000}
        sx={{ marginTop: "75px" }}
      >
        <Alert type="success">Список успешно создан</Alert>
      </Snackbar>
      <AiVoiceIntro
        open={isAiVoiceIntroOpen}
        onClose={() => setIsAiVoiceIntroOpen(false)}
        onConfirm={handleConfirmAiVoiceIntro}
      />
      {selectedList && (
        <>
          <DeleteDialog
            open={isDeleteListDialogOpen}
            name={selectedList.name}
            onClose={() => setIsDeleteListDialogOpen(false)}
            onOk={handleDeleteList}
            type="cписок"
          />
          <ShareListDialog
            open={isShareListDialogOpened}
            onClose={() => setIsShareListDialogOpened(false)}
            signedIn={signedIn}
            isOnline={isOnline}
            onFetchShareKey={handleFetchShareListKey}
            shareListKey={shareListKeyForSharing}
            selectedList={selectedList}
            telegramMiniAppUrl={config.TelegramMiniAppUrl}
            isTelegramMiniApp={!!telegramInitData}
          />
          <EditListDialog
            open={isEditListDialogOpen}
            onClose={() => setIsEditListDialogOpen(false)}
            listToEdit={selectedList}
            onConfirm={handleEditList}
          />
        </>
      )}
    </Stack>
  );
};
