import { Box, Button } from "@mui/material";
import React, { ReactNode } from "react";

import "./PageActionBar.scss";

export interface AppTopBarProps {
  onLeftButtonClick?: (() => void) | null;
  onRightButtonClick?: (() => void) | null;
  leftButtonText?: string;
  rightButtonText?: string;
  rightButtonIcon?: ReactNode;
}

export const PageActionBar = (props: AppTopBarProps) => {
  return (
    <Box className="page-action-bar">
      <Box className="page-action-bar-controls">
        {props.onLeftButtonClick && (
          <Button
            className="page-action-bar-controls-button"
            onClick={props.onLeftButtonClick}
          >
            {props.leftButtonText}
          </Button>
        )}
        {props.onRightButtonClick && (
          <Button
            className="page-action-bar-controls-button"
            onClick={props.onRightButtonClick}
          >
            {props.rightButtonText}
            {props.rightButtonIcon}
          </Button>
        )}
      </Box>
    </Box>
  );
};
