import { ListItemCategoryInternalModel } from "../../../services/internalStorage/models/ListItemCategoryInternalModel";
import {
  ListItemInternalModel,
  ListItemUpdateParams,
} from "../../../services/internalStorage/models/ListItemInternalModel";
import { CurtainPopup } from "../../CurtainPopup/CurtainPopup";
import { ListItemForm } from "../../ListItemForm/ListItemForm";

export interface EditListItemDialogProps {
  open: boolean;
  onClose: () => void;
  onFormSubmit: (data: ListItemUpdateParams) => Promise<void>;
  onImageUpload: (image: File) => Promise<string | null>;
  categories: ListItemCategoryInternalModel[];
  listItem?: ListItemInternalModel;
}

export const EditListItemDialog = (props: EditListItemDialogProps) => {
  return (
    <CurtainPopup open={props.open} onClose={props.onClose}>
      <ListItemForm
        id="list-item-form"
        title="Редактирование"
        onClose={props.onClose}
        onSubmit={props.onFormSubmit}
        onImageUpload={props.onImageUpload}
        categories={props.categories}
        initialData={props.listItem}
      />
    </CurtainPopup>
  );
};
